import { Component, OnInit, EventEmitter, Output, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { SelectItem, ConfirmationService } from 'primeng/primeng';

import { ProjectService } from '@shared/services/project.service';
import { DataService } from "@shared/services/data.service";

@Component({
    selector: 'app-inventory-header',
    templateUrl: 'inventory-header.component.html',
    styleUrls: ['./inventory-header.component.scss']
})
export class InventoryHeaderComponent implements OnInit, OnChanges {
    @Input() public selectedType: string;
    @Input('inventory') inventory;
    @Input('project') project;
    @Input('inventories') inventories;
    @Input('volumeTotal') volumeTotal;
    @Input('assemblyTotal') assemblyTotal;
    @Input('packingTotal') packingTotal;
    @Input('meterboxTotal') meterboxTotal;

    @Output('inventoryChange') inventoryChange = new EventEmitter;
    @Output() public onTypeChange: EventEmitter<string> = new EventEmitter<string>();

    public floor;
    public inventoryList: SelectItem[] = [];

    constructor(public projectService: ProjectService,
                private router: Router,
                private dataService: DataService,
                private confirmationService: ConfirmationService,
    ) {
    }

    public ngOnInit() {
        this.project = this.projectService.getProject();
    }

    public ngOnChanges() {
        this.loadInventories();
    }

    /**
     * Set inventory type
     *
     * @param type: string
     * @return void
     */
    public onInventoryTypeChange(type: string): void {
        this.onTypeChange.emit(type);
    }

    /**
     * When new inventory is selected
     *
     * @return void
     */
    public onInventoryChange(): void {
        this.inventoryChange.emit(this.inventory);
    }

    /**
     * On add inventory click
     *
     * @return void
     */
    public onAddClick(): void {
        this.router.navigateByUrl('/admin/project/' + this.project.id + '/inventory(popup:admin/project/inventory/add)');
    }

    /**
     * Delete selected inventory
     *
     * @return void
     */
    public onDeleteClick(): void {
        this.confirmationService.confirm({
            message: 'Wilt u deze inventarisatie verwijderen?',
            header: 'Bevestiging',
            icon: 'fa fa-question-circle',
            accept: () => {
                this.projectService.deleteInventory(this.inventory.id);
            }
        });
    }

    /**
     * Load inventories for dropdown
     *
     * @return void
     */
    private loadInventories(): void {
        this.inventoryList = [];
        if (this.inventories) {
            this.inventories.forEach(inventory => {
                if (inventory) {
                    this.inventoryList.push({ label: inventory.name, value: inventory });
                }
            });

            this.inventoryList = this.dataService.sortDropdownByLabel(this.inventoryList);
        }
    }
}
