import { Component, OnInit } from '@angular/core';
import { ApiService } from '@blueprint/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { OfflineTableOptions } from '@shared/controls/table/offline-table.component';
import { ProjectService } from '@shared/services/project.service';
import { Project } from '@domain/models/project.model';

@Component({
    selector: 'app-inventories-client-search',
    templateUrl: 'client-search.component.html'
})

export class InventoryClientSearchComponent implements OnInit {
    public tableOptions: OfflineTableOptions;
    private form;
    private project: Project;

    public constructor(
        private api: ApiService,
        private router: Router,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private projectService: ProjectService
    ) {
    }

    ngOnInit(): void {
        this.project = this.projectService.getProject();

        this.tableOptions = new OfflineTableOptions({
            paging: true,
            columns: [
                { title: 'Naam', name: 'name' }
            ],
            url: '/client',
            tableName: 'clients'
        });
    }

    public onCloseClick() {
        this.router.navigateByUrl('/admin/project/' + (this.project.id || 'new') + '/client');
    }

    public onRowClick(data: any) {
        this.projectService.getClient(data.id);
        this.router.navigateByUrl('/admin/project/' + (this.project.id || 'new') + '/client');
    }
}
