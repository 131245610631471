import { DomainModel } from '@domain/domain.model';
import { Client } from '@domain/models/client.model';
import { AddressType } from '@domain/models/address-type.model';

import * as uuid from 'uuid/v4';
import { SelectItem } from '@node_modules/primeng/components/common/selectitem';

export class Address extends DomainModel {
  // Configuration
  public entity = 'address';
  public table = 'addresses';
  public schema = 'id, remote_id, project_id';
  public sync = true;
  public id: string;

  // Fields
  public address_type_id?: number;
  public project_id?: string;
  public type?: string;
  public building_type?: string;
  public street?: string;
  public housenumber?: string;
  public housenumber_add?: string;
  public zipcode?: string;
  public city?: string;
  public country?: string;
  public floor?: string;
  public accessible_with_max?: string;
  public distance_to_building?: string;
  public parking_charge?: string;
  public lon?: string;
  public lat?: string;
  public email?: string;

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public async init() {
  // Set relations
    await AddressType.query.get(this.address_type_id).then(result => {
      this.type = result.name;
    });
  }

  /**
  * Return a display friendly name
  */
  public getDisplayName(): string {
    return `${this.type}: ${this.street} ${this.housenumber}${
      this.housenumber_add
    }, ${this.zipcode}, ${this.city}, ${this.country}`;
  }

  public getData(): any {
    return {
      id: this.id,
      project_id: this.project_id,
      address_type_id: this.address_type_id,
      street: this.street || '',
      housenumber: this.housenumber || '',
      housenumber_add: this.housenumber_add || '',
      zipcode: this.zipcode || '',
      city: this.city || '',
      country: this.country || '',
      lon: this.lon || 0,
      lat: this.lat || 0,
      email: this.email || ''
    };
  }

  /**
  * Returns the project status list options
  */
  public static getBuildingTypeList(): SelectItem[] {
    return [
      { label: '2-Onder-1 Kap Herenhuis', value: '2-Onder-1 Kap Herenhuis' },
      { label: '2-Onder-1 Kap Hoekwoning', value: '2-Onder-1 Kap Hoekwoning' },
      { label: '2-Onder-1-Kap', value: '2-Onder-1-Kap' },
      { label: 'Aanleunwoning', value: 'Aanleunwoning' },
      { label: 'Achterhuis', value: 'Achterhuis' },
      { label: 'Antiekwinkel', value: 'Antiekwinkel' },
      { label: 'Appartement', value: 'Appartement' },
      { label: 'Atelier', value: 'Atelier' },
      { label: 'Bedrijfspand', value: 'Bedrijfspand' },
      { label: 'Bedrijfsunit', value: 'Bedrijfsunit' },
      { label: 'Bedrijfswoning', value: 'Bedrijfswoning' },
      { label: 'Beneden + Bovenwoning', value: 'Beneden + Bovenwoning' },
      { label: 'Beneden + Bovenwoning', value: 'Beneden + Bovenwoning' },
      { label: 'Benedenwoning', value: 'Benedenwoning' },
      { label: 'Benedenwoning', value: 'Benedenwoning' },
      { label: 'Berging', value: 'Berging' },
      { label: 'Beurs', value: 'Beurs' },
      { label: 'Bijhuis', value: 'Bijhuis' },
      { label: 'Boerderij', value: 'Boerderij' },
      { label: 'Boot', value: 'Boot' },
      { label: 'Bordeel', value: 'Bordeel' },
      { label: 'Bovenhuis', value: 'Bovenhuis' },
      { label: 'Bovenwoning', value: 'Bovenwoning' },
      { label: 'Brasserie', value: 'Brasserie' },
      { label: 'Bungalow', value: 'Bungalow' },
      { label: 'Camping', value: 'Camping' },
      { label: 'Clubhuis', value: 'Clubhuis' },
      { label: 'Container', value: 'Container' },
      { label: 'Cultureel Centrum', value: 'Cultureel Centrum' },
      { label: 'Dak', value: 'Dak' },
      { label: 'Dakterras', value: 'Dakterras' },
      { label: 'Dienstwoning', value: 'Dienstwoning' },
      { label: 'Dijkwoning', value: 'Dijkwoning' },
      { label: 'Driedubbel Bovenhuis', value: 'Driedubbel Bovenhuis' },
      { label: 'Drive-Inn Woning', value: 'Drive-Inn Woning' },
      { label: 'Dubbel Bovenhuis', value: 'Dubbel Bovenhuis' },
      { label: 'Eetcafé', value: 'Eetcafé' },
      { label: 'Evenementenhal', value: 'Evenementenhal' },
      { label: 'Flat', value: 'Flat' },
      { label: 'Galerijflat', value: 'Galerijflat' },
      { label: 'Galerijwoning', value: 'Galerijwoning' },
      { label: 'Garage', value: 'Garage' },
      { label: 'Gemeentehuis', value: 'Gemeentehuis' },
      { label: 'Geschakelde Patiowoning', value: 'Geschakelde Patiowoning' },
      { label: 'Geschakelde Villa', value: 'Geschakelde Villa' },
      { label: 'Geschakelde Woning', value: 'Geschakelde Woning' },
      { label: 'Gezinsvervangend Tehuis', value: 'Gezinsvervangend Tehuis' },
      { label: 'Gezondheidscentrum', value: 'Gezondheidscentrum' },
      { label: 'Grachtenappartement', value: 'Grachtenappartement' },
      { label: 'Grachtenpand', value: 'Grachtenpand' },
      { label: 'Groeps accommodatie', value: 'Groeps accommodatie' },
      { label: 'Half vrijstaand Herenhuis', value: 'Half vrijstaand Herenhuis' },
      { label: 'Half vrijstaande Semi-Bungalow', value: 'Half vrijstaande Semi-Bungalow' },
      { label: 'half vrijstaande Villa', value: 'half vrijstaande Villa' },
      { label: 'hal vrijstaande Woning', value: 'hal vrijstaande Woning' },
      { label: 'half vrijstaande Woning', value: 'half vrijstaande Woning' },
      { label: 'half vrijstaande Boerderijwoning', value: 'half vrijstaande Boerderijwoning' },
      { label: 'Herenboerderij', value: 'Herenboerderij' },
      { label: 'Herenhuis', value: 'Herenhuis' },
      { label: 'Hoek Dubbel Bovenhuis', value: 'Hoek Dubbel Bovenhuis' },
      { label: 'Hoek Herenhuis', value: 'Hoek Herenhuis' },
      { label: 'Hoekappartement', value: 'Hoekappartement' },
      { label: 'Hoekhuis', value: 'Hoekhuis' },
      { label: 'Hoekwoning', value: 'Hoekwoning' },
      { label: 'Hoekwoning', value: 'Hoekwoning' },
      { label: 'Hof woning', value: 'Hof woning' },
      { label: 'Hotel', value: 'Hotel' },
      { label: 'Houtlijm', value: 'Houtlijm' },
      { label: 'Huisartsenpraktijk', value: 'Huisartsenpraktijk' },
      { label: 'Kantine', value: 'Kantine' },
      { label: 'Kantoor Bungalow', value: 'Kantoor Bungalow' },
      { label: 'Kantoorpand', value: 'Kantoorpand' },
      { label: 'Kasteel', value: 'Kasteel' },
      { label: 'Kelderbox', value: 'Kelderbox' },
      { label: 'Kerk', value: 'Kerk' },
      { label: 'Kinderdagverblijf', value: 'Kinderdagverblijf' },
      { label: 'Klooster', value: 'Klooster' },
      { label: 'Koetshuis', value: 'Koetshuis' },
      { label: 'Koetshuis', value: 'Koetshuis' },
      { label: 'Laboratorium', value: 'Laboratorium' },
      { label: 'Landhuis', value: 'Landhuis' },
      { label: 'Landhuis', value: 'Landhuis' },
      { label: 'Loods', value: 'Loods' },
      { label: 'Magazijn', value: 'Magazijn' },
      { label: 'Maisonnette', value: 'Maisonnette' },
      { label: 'Museum', value: 'Museum' },
      { label: 'Nonnenwoning', value: 'Nonnenwoning' },
      { label: 'Opslag', value: 'Opslag' },
      { label: 'Paleis', value: 'Paleis' },
      { label: 'Parkeerterrein', value: 'Parkeerterrein' },
      { label: 'Parochiewoning', value: 'Parochiewoning' },
      { label: 'Parterre Appartement', value: 'Parterre Appartement' },
      { label: 'Parterrewoning', value: 'Parterrewoning' },
      { label: 'Partycentrum', value: 'Partycentrum' },
      { label: 'Patiowoning', value: 'Patiowoning' },
      { label: 'Penthouse', value: 'Penthouse' },
      { label: 'Portiekflat', value: 'Portiekflat' },
      { label: 'Portiekwoning', value: 'Portiekwoning' },
      { label: 'Porto Cabin', value: 'Porto Cabin' },
      { label: 'Praktijkwoning', value: 'Praktijkwoning' },
      { label: 'Recreatiepark', value: 'Recreatiepark' },
      { label: 'Rentmeester woning', value: 'Rentmeester woning' },
      { label: 'Residentie', value: 'Residentie' },
      { label: 'Restaurant', value: 'Restaurant' },
      { label: 'Rijtjeshuis', value: 'Rijtjeshuis' },
      { label: 'Schakel Villa', value: 'Schakel Villa' },
      { label: 'Schip', value: 'Schip' },
      { label: 'Schipperswoning', value: 'Schipperswoning' },
      { label: 'School', value: 'School' },
      { label: 'Schuur', value: 'Schuur' },
      { label: 'Self storage', value: 'Self storage' },
      { label: 'Semibungalow', value: 'Semibungalow' },
      { label: 'Seniorenwoning', value: 'Seniorenwoning' },
      { label: 'Service Villa', value: 'Service Villa' },
      { label: 'Service appartement', value: 'Service appartement' },
      { label: 'Service flat', value: 'Service flat' },
      { label: 'Showroom', value: 'Showroom' },
      { label: 'Souterrain Appartement', value: 'Souterrain Appartement' },
      { label: 'Split-Level Woning', value: 'Split-Level Woning' },
      { label: 'Stadion', value: 'Stadion' },
      { label: 'Stadshuisje', value: 'Stadshuisje' },
      { label: 'Station', value: 'Station' },
      { label: 'Steiger', value: 'Steiger' },
      { label: 'Stort', value: 'Stort' },
      { label: 'Strandtent', value: 'Strandtent' },
      { label: 'Studentenkamer', value: 'Studentenkamer' },
      { label: 'Studentenwoning', value: 'Studentenwoning' },
      { label: 'Studentenwoning', value: 'Studentenwoning' },
      { label: 'Studio', value: 'Studio' },
      { label: 'Theater', value: 'Theater' },
      { label: 'Topappartement', value: 'Topappartement' },
      { label: 'Tophoekappartement', value: 'Tophoekappartement' },
      { label: 'Tuinhuis', value: 'Tuinhuis' },
      { label: 'Tussenwoning', value: 'Tussenwoning' },
      { label: 'Vakantie- En Attractiepark', value: 'Vakantie- En Attractiepark' },
      { label: 'Vakantiehuis', value: 'Vakantiehuis' },
      { label: 'Vakantiewoning', value: 'Vakantiewoning' },
      { label: 'Veilinghuis', value: 'Veilinghuis' },
      { label: 'Verhuisbedrijf', value: 'Verhuisbedrijf' },
      { label: 'Verpleeghuis', value: 'Verpleeghuis' },
      { label: 'Verzorgingshuis', value: 'Verzorgingshuis' },
      { label: 'Villa', value: 'Villa' },
      { label: 'Vrachtwagen', value: 'Vrachtwagen' },
      { label: 'Vrijstaand Bovenhuis', value: 'Vrijstaand Bovenhuis' },
      { label: 'Vrijstaand Huis', value: 'Vrijstaand Huis' },
      { label: 'Vrijstaand Huis En Bijhuisje', value: 'Vrijstaand Huis En Bijhuisje' },
      { label: 'Vrijstaand Woonhuis', value: 'Vrijstaand Woonhuis' },
      { label: 'Vrijstaande Geschakelde Eengezinswoning', value: 'Vrijstaande Geschakelde Eengezinswoning' },
      { label: 'Vrijstaande Semi-Bungalow', value: 'Vrijstaande Semi-Bungalow' },
      { label: 'Vrijstaande Villa', value: 'Vrijstaande Villa' },
      { label: 'Vrijstaande Woning', value: 'Vrijstaande Woning' },
      { label: 'Werkplaats', value: 'Werkplaats' },
      { label: 'Winkel', value: 'Winkel' },
      { label: 'Winkel-/Woonhuis', value: 'Winkel-/Woonhuis' },
      { label: 'Woning', value: 'Woning' },
      { label: 'Woonboerderij', value: 'Woonboerderij' },
      { label: 'Woonboot', value: 'Woonboot' },
      { label: 'Wooncomplex', value: 'Wooncomplex' },
      { label: 'Woonhuis', value: 'Woonhuis' },
      { label: 'Woonzorgcentrum', value: 'Woonzorgcentrum' },
      { label: 'Woonzorgcomplex', value: 'Woonzorgcomplex' },
      { label: 'Ziekenhuis', value: 'Ziekenhuis' },
      { label: 'Zomerhuis', value: 'Zomerhuis' },
      { label: 'Zorgcentrum', value: 'Zorgcentrum' },
      { label: 'Zorgvilla', value: 'Zorgvilla' },
      { label: 'Zorgwoning', value: 'Zorgwoning' },
    ];
  }
}
