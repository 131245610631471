import { DomainModel } from '@domain/domain.model';

export class Specialty extends DomainModel {

  // Configuration
  public entity = 'specialty';
  public table = 'specialties';
  public schema = '++id, remote_id, project_type';
  public sync = true;

  // Fields
  public project_type: string;
  public name: string;
  public cost_rate: number;
  public has_hours: boolean;
  public has_remarks: boolean;
  public has_date: boolean;

  // Constructor
  constructor(attributes) {
    super(attributes);
  }

  public static listByProjectType(projectType: string) {
    return Specialty.query.where('project_type').equals(projectType).toArray();
  }
}
