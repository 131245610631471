import { NgModule } from '../../../node_modules/@angular/core';
import { BrowserModule } from '../../../node_modules/@angular/platform-browser';
import { FormsModule } from '../../../node_modules/@angular/forms';

// Libraries, 3d party components
import { DropdownModule, ConfirmationService } from '../../../node_modules/primeng/primeng';

// Blueprint components
import { AlertComponent } from './components/alert/alert.component';
import { LoadingComponent } from './components/loading/loading.component';
import { SwitchComponent } from './components/switch/switch.component';
import { SwitchOptionComponent } from './components/switch/switch-option.component';
import { ZipCodeValidatorDirective } from './directives/validation/zipcode-validator.directive';

// Blueprint services
import { ApiService } from './services/api.service';
import { AuthService } from './auth/auth.service';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { ConfirmDialogModule } from '../../../node_modules/primeng/components/confirmdialog/confirmdialog';

@NgModule({
  declarations: [
    AlertComponent,
    ConfirmComponent,
    LoadingComponent,
    SwitchComponent,
    SwitchOptionComponent,
    ZipCodeValidatorDirective
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ConfirmDialogModule
  ],
  exports: [
    DropdownModule,
    AlertComponent,
    ConfirmComponent,
    LoadingComponent,
    SwitchComponent,
    SwitchOptionComponent,
    ConfirmDialogModule,
    ZipCodeValidatorDirective
  ],
  providers: [ApiService, AuthService, ConfirmationService],
})

export class EmendisBlueprintModule {
}
