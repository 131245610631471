import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ApiService } from '@blueprint/services/api.service';
import { AuthService } from '@blueprint/auth/auth.service';

import { Store } from '@domain/store';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ProjectOverviewComponent } from '@features/project/project-overview.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('projectOverview') projectOverview: ProjectOverviewComponent;
  private synchronised = false;

  constructor(
    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    private synchronisationService: SynchronisationService,
    private translate: TranslateService
  ) {
    this.translate.setDefaultLang('nl');
    this.translate.use('nl');

    const onAuthError = () => {
      this.auth.logout().subscribe();
      this.router.navigateByUrl('/login');
    };

    this.api.onAuthError = onAuthError;
  }

  ngOnInit(): void {}
}
