import { Component, EventEmitter, Output, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ProjectService } from '@shared/services/project.service';
import { SelectItem } from 'primeng/primeng';
import { Inventory } from '@domain/models/inventory.model';
import { Project } from '@domain/models/project.model';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-inventory-add',
  templateUrl: 'inventory-add.component.html'
})

export class InventoryAddComponent implements OnInit, OnDestroy {
  private inventory = new Inventory({});

  private subscriptionProjectLoaded: Subscription;
  private subscriptionDefaultInventoriesLoaded: Subscription;

  public form;
  public showErrors = false;
  public project = new Project({});
  public defaultInventories: SelectItem[] = [];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private projectService: ProjectService) {
  }

  ngOnInit() {
    this.initForm();
    this.project = this.projectService.getProject();

    this.projectService.getDefaultInventories();

    this.subscriptionDefaultInventoriesLoaded = this.projectService.defaultInventoriesLoaded.subscribe((inventories) => {
      this.defaultInventories = inventories;
      this.initForm();
    });

    // Reload when project changes
    this.subscriptionProjectLoaded = this.projectService.projectLoaded.subscribe((project) => {
      this.project = project;
    });
  }

  ngOnDestroy() {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }
    if (this.subscriptionDefaultInventoriesLoaded) {
      this.subscriptionDefaultInventoriesLoaded.unsubscribe();
    }
  }

  initForm() {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control(this.inventory.id),
      name: this.formBuilder.control('', Validators.required),
      building: this.formBuilder.control(this.project.type === 'private' ? 'huis' : '', Validators.required),
      floor: this.formBuilder.control(this.project.type === 'private' ? 0 : '', Validators.required),
      default_inventory_id: this.formBuilder.control(this.defaultInventories[0] ? this.defaultInventories[0].value : ''),
    });
  }

  private onSubmit() {
    if (this.form.valid) {
      this.inventory = new Inventory({ ...this.form.value });
      this.inventory.project_id = this.project.id;
      this.projectService.saveNewInventory(this.inventory);
      this.onCloseClick();
    } else {
      this.showErrors = true;
    }
  }

  private onCloseClick() {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/inventory');
  }
}
