import { Component, EventEmitter, Output, OnInit, Input, OnDestroy } from '@angular/core';
import { InventoryItem } from '@domain/models/inventory-item.model';
import { FormBuilder, Validators } from '@angular/forms';
import { ProjectService } from '@shared/services/project.service';
import { Router } from '@angular/router';
import { Project } from '@domain/models/project.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-inventory-add-item',
  templateUrl: 'inventory-add-item.component.html',
  styleUrls: ['inventory-add-item.component.scss']
})

export class InventoryAddItemComponent implements OnInit, OnDestroy {
  @Output() public newItem = new EventEmitter;

  public form;
  public showErrors = false;
  public project: Project;

  private inventoryItem = new InventoryItem({});
  private subscriptionProjectLoaded: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private projectService: ProjectService,
    private router: Router
  ) {
    this.project = this.projectService.getProject();

    /** Listen for project updates */
    this.subscriptionProjectLoaded = this.projectService.projectLoaded.subscribe((project) => {
      this.project = project;
    });
  }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy() {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }
  }

  public onCloseClick() {
    this.router.navigateByUrl(`/admin/project/${this.project.id}/inventory`);
  }

  initForm() {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control(this.inventoryItem.id),
      name: this.formBuilder.control('', Validators.required),
      remarks: this.formBuilder.control(''),
      volume: this.formBuilder.control(''),
    });
  }

  submit() {
    if (this.form.valid) {
      this.inventoryItem = new InventoryItem({ ...this.form.value });
      this.projectService.inventoryItemAdded.next(this.inventoryItem);
      this.onCloseClick();
    } else {
      this.showErrors = true;
    }
  }
}
