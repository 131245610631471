import { DomainModel } from '@domain/domain.model';
import { Client } from '@domain/models/client.model';
import { Address } from '@domain/models/address.model';
import { Activity } from '@domain/models/activity.model';
import { Specialty } from '@domain/models/specialty.model';
import { ProjectActivity } from '@domain/models/project-activity.model';
import { ProjectSpecialty } from '@domain/models/project-specialty.model';
import { Inventory } from '@domain/models/inventory.model';
import { Quotation } from '@domain/models/quotation.model';
import { SelectItem } from 'primeng/api';

import * as uuid from 'uuid/v4';
import { User } from '@domain/models/user.model';
import { ProjectMaterial } from '@domain/models/project-material.model';
import { CustomSpecialty } from '@domain/models/custom-specialty.model';
import { RelationGroup } from '@domain/models/relation-group.model';

export class Project extends DomainModel {
  // Configuration
  public entity = 'project';
  public table = 'projects';
  public schema = 'id';
  public sync = true;
  public id: string;

  // Fields
  public _original: any;
  public is_new = false;
  public is_changed = false;

  public type = 'private';
  public projectType?: string;
  public status = 'new';
  public type_removal: string;
  public type_day: string;
  public type_floor: string;
  public client_id?: string;
  public clientName?: string;
  public accountmanager_id?: number;
  public accountmanagerName?: string;
  public executor_id?: number;
  public reference_nr?: string;
  public own_description_activities?: string;
  public client_description_activities?: string;
  public relation?: string;
  public relationGroup = new RelationGroup({});
  public client = new Client({});
  public accountmanager = new User({});
  public addresses: Address[] = [];
  public specialties: ProjectSpecialty[] = [];
  public customSpecialties: CustomSpecialty[] = [];
  public activities: ProjectActivity[] = [];
  public inventories: Inventory[] = [];
  public quotations: Quotation[] = [];
  public materials: ProjectMaterial[] = [];

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public async init() {
    // Set project type name
    switch (this.type) {
      case 'business':
        this.projectType = 'Zakelijk';
        break;
      case 'private':
        this.projectType = 'Particulier';
        break;
      default:
        this.projectType = '?';
        break;
    }

    // Set relations
    if (this.client_id) {
      this.client = await Client.query.get(this.client_id);
      this.clientName = this.client ? this.client.name : '';

      if (this.accountmanager_id) {
        this.accountmanager = await User.query.get(this.accountmanager_id);
        this.accountmanagerName = this.accountmanager
          ? this.accountmanager.name
          : '';
      }
    }
  }

  public async loadActivities() {
    if (!this.id) {
      return;
    }

    this.activities = await ProjectActivity.query
      .where('project_id')
      .equals(this.id)
      .toArray();

    for (const activity of this.activities) {
      await activity.init();
    }

    // Fill activities if not available yet
    if (!this.activities || this.activities.length === 0) {
      const activities = await Activity.query
        .where('project_type')
        .equals(this.type)
        .toArray();

      for (const activity of activities) {
        await activity.init();
        const projectActivity = new ProjectActivity({
          activity_id: activity.id,
          project_id: this.id
        });

        await projectActivity.init();

        this.activities.push(projectActivity);
      }
    }
  }

  public async loadSpecialties() {
    if (!this.id) {
      return;
    }

    this.specialties = await ProjectSpecialty.query
      .where('project_id')
      .equals(this.id)
      .toArray();

    for (const specialty of this.specialties) {
      await specialty.init();
    }

    // Fill specialties if not available yet
    if (!this.specialties || this.specialties.length === 0) {
      const specialties = await Specialty.query
        .where('project_type')
        .equals(this.type)
        .toArray();
      this.specialties = [];

      for (const specialty of specialties) {
        await specialty.init();
        const projectSpecialty = new ProjectSpecialty({
          specialty_id: specialty.id,
          project_id: this.id
        });

        await projectSpecialty.init();

        this.specialties.push(projectSpecialty);
      }
    }
  }

  public async loadInventories() {
    this.inventories = await Inventory.query
      .where('project_id')
      .equals(this.id)
      .toArray();

    for (const inventory of this.inventories) {
      await inventory.init();
    }
  }

  public async loadQuotations() {
    this.quotations = await Quotation.query
      .where('project_id')
      .equals(this.id)
      .toArray();

    for (const quotation of this.quotations) {
      await quotation.init();
    }
  }

  public async loadAddresses() {
    this.addresses = await Address.query
      .where('project_id')
      .equals(this.id)
      .toArray();

    for (const address of this.addresses) {
      await address.init();
    }
  }

  public async loadMaterials() {
    this.materials = await ProjectMaterial.query
        .where('project_id')
        .equals(this.id)
        .toArray();

    for (const material of this.materials) {
      await material.init();
    }
  }

  /**
   * Retrieves the total price from desbribed specialty
   *
   * @param specialtyName
   * @returns number
   */
  public async getSpecialtyTotalPrice(specialtyName: string): Promise<number> {
    if (!this.specialties || this.specialties.length === 0) {
      await this.loadSpecialties();
    }

    // Find the specialty and return the price
    const specialty = this.specialties.find((specialtyItem) => specialtyItem.specialty.name === specialtyName);

    if (specialty && specialty.applicable) {
      // Determine whether the specialty has been toggled true, if not, don't show it and return null
      if (specialty.specialty.has_hours) {
        return specialty.hours_estimate * specialty.specialty.cost_rate;
      } else {
        // If specialty does not contain hours, only display the inital cost rate
        return specialty.specialty.cost_rate;
      }
    }

    return null;
  }

  public hasActivities() {
    return (
      this.activities && this.activities.some(activity => activity.applicable)
    );
  }

  public hasSpecialties() {
    return (
      this.specialties &&
      this.specialties.some(specialty => specialty.applicable)
    );
  }

  public getData(): any {
    return {
      id: this.id,
      client_id: this.client_id,
      type: this.type || '',
      status: this.status || '',
      type_removal: this.type_removal || '',
      type_day: this.type_day || '',
      type_floor: this.type_floor || '',
      reference_nr: this.reference_nr || '',
      own_description_activities: this.own_description_activities || '',
      client_description_activities: this.client_description_activities || '',
      executor_id: this.executor_id,
      accountmanager_id: this.accountmanager_id
    };
  }

  /**
   * Returns the project status list options
   */
  public static getStatusList(): SelectItem[] {
    return [
      { label: 'Nieuw', value: 'new' },
      { label: 'Pending', value: 'pending' },
      { label: 'Booked', value: 'booked' },
      { label: 'Lost', value: 'lost' },
      { label: 'Inactive', value: 'inactive' }
    ];
  }

  /**
   * Returns the project removal type list options
   */
  public static getTypeRemovalList(): SelectItem[] {
    return [
      { label: 'Reguliere verhuizing', value: 'big-pieces' },
      { label: 'Zelf verhuizing', value: 'self' },
      { label: 'Speciale verhuizing', value: 'special' },
    ];
  }

  /**
   * Returns the project removal type list options
   */
  public static getTypeDayList(): SelectItem[] {
    return [
      { label: 'Werkdagen', value: 'working-days' },
      { label: 'Weekend', value: 'weekend' },
    ];
  }

  /**
   * Returns the project floor list options
   */
  public static getTypeFloorList(): SelectItem[] {
    return [
      { label: 'Van begane grond naar begane grond', value: 'ground-floor' },
      { label: 'Van begane grond naar etage (of andersom)', value: 'ground-floor-to-floor' },
      { label: 'Van etage naar etage', value: 'floor-to-floor' },
    ];
  }

  /**
   * Returns the status name by value
   * @param status string
   */
  public static getStatusName(status: string): string {
    const result = this.getStatusList().find(item => item.value === status);

    return result ? result.label : '';
  }

  /**
   * Returns the project status list options
   */
  public static getRelationList(): SelectItem[] {
    return [
      { label: 'Website', value: 'website' },
      { label: 'Erkende verhuizer', value: 'acknowledged_mover' },
      { label: 'Mondeling', value: 'verbal' },
      { label: 'Overig', value: 'other' }
    ];
  }
}
