import { Injectable } from '../../../../node_modules/@angular/core';
import { Http, Headers, Response, ResponseContentType } from '../../../../node_modules/@angular/http';
import { Observable } from '../../../../node_modules/rxjs/Observable';
import { AuthService } from '@blueprint/auth/auth.service';
import { environment } from '@environments/environment';

@Injectable()
export class ApiService {

  constructor(private http: Http, private authService: AuthService) { }

  public onAuthError: () => void = () => {
    if (!environment.production) {
      console.warn('onAuthError has to be set in order to redirect to login page');
    }
  }

  public get(url: string): Observable<any> {
    return this.http.get(`${environment.baseUrl}${url}`,
      { headers: this.getHeaders(), responseType: ResponseContentType.Json })
      .map((response: Response) => response.json())
      .catch((response: Response) => this.handleError(response));
  }

  public getBlob(url: string, type: string): Observable<any> {
    const headers = this.getHeaders();
    headers.delete('Content-Type');

    return this.http.get(`${environment.baseUrl}${url}`,
      { headers: headers, responseType: ResponseContentType.Blob })
      .map((response: Response) => new Blob([response.blob()], { type: type }))
      .catch((response: Response) => this.handleError(response));
  }

  public post(url: string, data: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}${url}`,
      data,
      { headers: this.getHeaders(), responseType: ResponseContentType.Json })
      .map((response: Response) => response.json())
      .catch((response: Response) => this.handleError(response));
  }

  public patch(url: string, data: any): Observable<any> {
    return this.http.patch(`${environment.baseUrl}${url}`,
      data,
      { headers: this.getHeaders(), responseType: ResponseContentType.Json })
      .map((response: Response) => response.json())
      .catch((response: Response) => this.handleError(response));
  }

  public delete(url: string): Observable<any> {
    return this.http.delete(`${environment.baseUrl}${url}`,
      { headers: this.getHeaders(), responseType: ResponseContentType.Json })
      .map((response: Response) => response.json())
      .catch((response: Response) => this.handleError(response));
  }

  private getHeaders() {
    const headers = new Headers();
    headers.append('Authorization', 'Bearer ' + this.authService.getToken());
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    return headers;
  }

  private handleError(error: any): Observable<any> {

    if (error.status === 401) {
      this.onAuthError();
    }

    return Observable.throw(error);
  }
}
