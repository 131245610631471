import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Project } from '@domain/models/project.model';
import { Address } from '@domain/models/address.model';
import { WorkAssignment } from '@domain/models/work-assignment.model';
import { WorkAssignmentItem } from '@domain/models/work-assignment-item.model';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ProjectService } from '@shared/services/project.service';
import { DataService, QueryOptions } from '@shared/services/data.service';
import { QuotationSignatureComponent } from '../quotation/signature/quotation-signature.component';

@Component({
  selector: 'app-inventory-work-assignment-detail',
  templateUrl: 'work-assignment-detail.component.html',
  styleUrls: ['./work-assignment-detail.component.scss']
})
export class InventoryWorkAssignmentDetailComponent implements OnInit, OnDestroy {
  public project: Project;
  public workAssignment: WorkAssignment;
  public addressOptions: Array<{ label: string, value: string }> = [];
  public times = [];

  private addresses: Address[];

  @ViewChild('clientSignature') clientSignature: QuotationSignatureComponent;
  @ViewChild('teamleaderSignature') teamleaderSignature: QuotationSignatureComponent;

  private subscriptionProjectLoaded: Subscription;

  constructor(private route: ActivatedRoute, private syncService: SynchronisationService,
    private projectService: ProjectService, private dataService: DataService) {
    this.project = this.projectService.getProject();
    this.addresses = this.project.addresses;
    this.workAssignment = new WorkAssignment({});
    this.workAssignment.setDefaults();

    this.subscriptionProjectLoaded = this.projectService.projectLoaded.subscribe(project => {
      this.project = project;
      this.addresses = this.project.addresses;
      this.initialize();
    });

    // Fill times items
    for (let i = 0; i < 24; i++) {
      this.times.push({ label: i + ':00', value: i + ':00' });
      this.times.push({ label: i + ':15', value: i + ':15' });
      this.times.push({ label: i + ':30', value: i + ':30' });
      this.times.push({ label: i + ':45', value: i + ':45' });
    }

    this.initialize();
  }

  public ngOnInit() {
    // Get id of address to edit by route params
    this.route.params.subscribe(async params => {
      const result = await this.projectService.getWorkAssignment(params['id']);
      if (result) {
        this.workAssignment = result;
      }
    });
  }

  public ngOnDestroy() {
    this.projectService.saveWorkAssignment(this.workAssignment);
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }
  }

  public onAddPersonClick() {
    this.addWorkAssignmentItem('person');
  }

  public onAddCarClick() {
    this.addWorkAssignmentItem('car');
  }

  public onDeleteItemClick(item: WorkAssignmentItem) {
    this.removeWorkAssignmentItem(item);
  }

  private addWorkAssignmentItem(type: string) {
    const item = new WorkAssignmentItem({ work_assignment_id: this.workAssignment.id, type: type });
    item.setDefaults();
    this.workAssignment.items.push(item);
  }

  private removeWorkAssignmentItem(item: WorkAssignmentItem) {
    const index = this.workAssignment.items.indexOf(item);
    if (index === -1) {
      return;
    }
    this.workAssignment.items.splice(index , 1);
    this.projectService.deleteWorkAssignmentItem(item);
  }

  private initialize() {
    // Add address options
    this.addressOptions = [];
    for (const address of this.addresses) {
      this.addressOptions.push({ label: address.getDisplayName(), value: address.id });
    }
  }

  private showClientSignatureForm() {
    this.clientSignature.showForm();
  }

  private showTeamleaderSignatureForm() {
    this.teamleaderSignature.showForm();
  }
}
