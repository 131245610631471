import { DomainModel } from '@domain/domain.model';

import * as uuid from 'uuid/v4';

export class InventoryItem extends DomainModel {
    // Configuration
    public entity = 'inventory-item';
    public table = 'inventory_items';
    public schema = 'id, remote_id, inventory_id';
    public sync = true;
    public id: string;

    // Fields
    public inventory_id: string;
    public inventory_type: string;
    public amount: number;
    public name: string;
    public volume: number;
    public meterbox: number;
    public remarks: string;
    public assemble: boolean;
    public disassemble: boolean;
    public image: string;

    // Constructor
    constructor(attributes) {
        super(attributes);

        if (!attributes.id) {
            this.id = uuid();
        }

        if (!attributes.inventory_type) {
            this.inventory_type = 'move';
        }
    }

    public getData(): any {
        return {
            id: this.id,
            inventory_id: this.inventory_id,
            inventory_type: this.inventory_type,
            amount: this.amount || 0,
            name: this.name || '',
            volume: this.volume || 0,
            remarks: this.remarks || '',
            assemble: this.assemble,
            disassemble: this.disassemble,
            image: this.image || null
        };
    }
}
