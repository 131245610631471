import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

// Libraries, 3d party components
import { PaginationModule } from 'ngx-bootstrap';

// Emendis Blueprint
import { EmendisBlueprintModule } from '@blueprint/emendis-blueprint.module';

// Custom components
import { OfflineTableComponent } from '@shared/controls/table/offline-table.component';
import { OnlineTableComponent } from '@shared/controls/table/online-table.component';
import { AsteriksComponent } from '@shared/controls/asteriks/asteriks.component';
import { AppPatternRestrictDirective } from '@shared/directives/app-pattern-restrict.directive';
import { DataTableModule } from 'primeng/primeng';
import { TooltipModule } from 'primeng/primeng';
import { MultiSelectModule } from 'primeng/primeng';
import { TableModule } from 'primeng/table';
import {TranslateLoader, TranslateModule} from '@node_modules/@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {HttpLoaderFactory} from '../app.module';

@NgModule({
  declarations: [
    OfflineTableComponent,
    OnlineTableComponent,
    AppPatternRestrictDirective,
    AsteriksComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    EmendisBlueprintModule,
    PaginationModule.forRoot(),
    DataTableModule,
    TableModule,
    TooltipModule,
    MultiSelectModule
  ],
  exports: [
    OfflineTableComponent,
    OnlineTableComponent,
    AsteriksComponent,
    AppPatternRestrictDirective,
    PaginationModule,
    MultiSelectModule,
    TranslateModule
  ]
})

export class SharedModule {}