import { Component, Input, Output, EventEmitter, OnInit } from '../../../../../node_modules/@angular/core';
import { ConfirmDialogModule, ConfirmationService } from '../../../../../node_modules/primeng/primeng';

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.component.html'
})
export class ConfirmComponent {
    @Input('content') content: {header: '', text: '', confirm: '', cancel: ''};
    @Output('confirmed') confirmed = new EventEmitter;

    constructor(private confirmationService: ConfirmationService) {
    }

    show() {
        this.confirmationService.confirm({
            message: this.content.text,
            header: this.content.header,
            accept: () => {
                this.confirmed.emit();
            }
        });
    }
}
