import { Component, OnChanges } from '@angular/core';

@Component({
  selector: 'app-material-header',
  templateUrl: 'material-header.component.html',
  styleUrls: ['./material-header.component.scss']
})
export class MaterialHeaderComponent implements OnChanges {

  public ngOnChanges() {
  }

}
