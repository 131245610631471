import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import * as uuid from 'uuid/v4';
import { Project } from '@domain/models/project.model';
import { DataService, QueryOptions } from '@shared/services/data.service';
import { Address } from '@domain/models/address.model';
import { Contact } from '@domain/models/contact.model';
import { InventoryItem } from '@domain/models/inventory-item.model';
import { DefaultInventory } from '@domain/models/default-inventory.model';
import { Inventory } from '@domain/models/inventory.model';
import { WorkAssignment } from '@domain/models/work-assignment.model';
import { WorkAssignmentItem } from '@domain/models/work-assignment-item.model';
import { ProjectMaterial } from '@domain/models/project-material.model';

/**
 * ProjectService
 * This service is designed to provide the project accross different classes and routing
 * IMPORTANT: include this service under provider section in a module.
 */
@Injectable()
export class ProjectService {
    private result;

    // Constants
    public maxInventoryItems = 100; // Maximum of items to show in inventory
    public maxListItems = 50; // MAximum of items to show in default inventory list

    // Observables
    public projectLoaded = new Subject<any>();
    public addressLoaded = new Subject<any>();
    public addressAdded = new Subject<any>();
    public quotationAdded = new Subject<any>();
    public contactsChanged = new Subject<any>();
    public contactsAdded = new Subject<any>();
    public clientChanged = new Subject<any>();
    public defaultInventoriesLoaded = new Subject<any>();
    public inventoryDeleted = new Subject<any>();
    public inventoryAdded = new Subject<any>();
    public materialChanged = new Subject<any>();
    public inventoryItemAdded = new Subject<InventoryItem>();

    // Models
    public project: Project;
    public address: Address;
    public contact: Contact;

    public dataQuery = new QueryOptions();

    constructor(private dataService: DataService, private router: Router) {
    }

    /**
     * Get project
     * If not exist create new project
     */
    public getProject(): Project {
        if (!this.project) {
            this.project = new Project({});
            this.project.client_id = null;
        }

        return this.project;
    }

    /**
     * CRUD PROJECT
     */
    public async newProject(): Promise<Project> {
        this.project = new Project({});

        // Create guid for new project
        this.project.id = uuid();
        this.project.is_new = true;

        await this.saveProject();

        return this.project;
    }

    public async addDefaultInventories(): Promise<void> {
        const defaultInventories = await DefaultInventory.query.toArray();
        for (const defaultInventory of defaultInventories) {
            await this.saveNewInventory(new Inventory({
                name: defaultInventory.name,
                default_inventory_id: defaultInventory.id,
                project_id: this.project.id,
                floor: '0',
                building: 'huis'
            }));
        }
    }

    /**
     * Load Project by id
     */
    public async loadProject(id: string): Promise<void> {
        this.project = await this.dataService.getById('projects', id);

        if (!this.project) {
            // Project not found, redirect to home screen
            await this.router.navigateByUrl('/');

            return;
        }

        await this.project.loadSpecialties();
        await this.project.loadActivities();
        await this.project.loadInventories();
        await this.project.loadQuotations();
        await this.project.loadAddresses();
        await this.project.loadMaterials();

        this.projectLoaded.next(this.project);
    }

    /**
     * Update updated_at field to mark project for synchronisation to backend
     * TODO This synchronisation mark should be more efficient and only applied for real updates
     */
    public async setProjectUpdated(): Promise<void> {
        this.project.is_changed = true;
        if (this.project.id) {
            await this.dataService.createOrUpdate('projects', this.project);
        }
    }

    /**
     * Save project with clients
     *
     * @returns {Promise<void>}
     */
    public async saveProject() {
        const newProjectId = await this.dataService.createOrUpdate('projects', this.project);

        // Check if private project has default projects loaded
        if (this.project.type === 'private' &&
            this.project.reference_nr &&
            this.project.reference_nr.length && (
                !this.project.inventories || this.project.inventories.length === 0
            )) {
            await this.addDefaultInventories();
        }

        await this.loadProject(newProjectId);
    }

    public async saveClientAndProject(): Promise<void> {
        await this.dataService.createOrUpdate('clients', this.project.client);

        this.project.client_id = this.project.client.id;

        await this.saveProject();
    }

    /**
     * Delete project with id
     * @param id Number
     */
    public async deleteProject(id) {
        await this.dataService.delete('projects', id);
    }

    /**
     * Save address
     *
     * @param address Address model
     */
    public async saveAddress(address): Promise<void> {
        await this.setProjectUpdated();

        this.dataService.createOrUpdate('addresses', address).then(_ => {
            this.addressAdded.next();
        });
    }

    /**
     * Save Quotation
     *
     * @param quotation Quotation model
     */
    public async saveQuotation(quotation): Promise<void> {
        await this.setProjectUpdated();

        // Update date string value
        // TODO Refactor to more sustainable solution
        quotation.updateDate();

        this.dataService.createOrUpdate('quotations', quotation).then(result => {
            this.quotationAdded.next();
        });
    }

    /**
     * Get address with id
     *
     * @param id Number
     */
    public async getAddress(id): Promise<void> {
        this.dataQuery = new QueryOptions({
            pageSize: 1,
            columns: [{ name: 'id', filter: id, filterMode: 'equals' }]
        });
        const address = await this.dataService.get('addresses', this.dataQuery, '/address');
        this.address = address[0];
        this.addressLoaded.next(this.address);
    }

    /**
     * Delete address with id
     *
     * @param id Number
     */
    public async deleteAddress(id): Promise<void> {
        await this.setProjectUpdated();
        await this.dataService.delete('addresses', id);
    }

    /**
     * Save contact
     *
     * @param contact Contact model
     */
    public async saveContact(contact): Promise<void> {
        await this.setProjectUpdated();

        this.dataService.createOrUpdate('contacts', contact).then(_ => {
            this.contactsAdded.next();
        });
    }

    /**
     * Get contact with id
     *
     * @param id Number
     */
    public async getContact(id): Promise<void> {
        this.dataQuery = new QueryOptions({
            pageSize: 1,
            columns: [{ name: 'id', filter: id, filterMode: 'equals' }]
        });
        const contact = await this.dataService.get('contacts', this.dataQuery, '/contact');
        this.contact = contact[0];
        this.contactsChanged.next(this.contact);
    }

    /**
     * Check if project has contacts
     *
     * @returns {Promise<boolean>}
     * @param projectId
     */
    public async hasContacts(projectId: string) {
        if (!this.project) {
            await this.loadProject(projectId);
        }

        if (!this.project || !this.project.client_id) {
            return false;
        }

        const criteria = { client_id: this.project.client_id };
        let contact = await this.dataService.getWhere('contacts', criteria, '/contact?client_id=' + this.project.client_id)
            .then(item => contact = item);

        return contact !== undefined;
    }

    /**
     * Delete contact with id
     *
     * @param id Number
     */
    public async deleteContact(id): Promise<void> {
        await this.setProjectUpdated();
        await this.dataService.delete('contacts', id);
    }

    /**
     * Get work assignment by id
     */
    public async getWorkAssignment(id: number) {
        this.dataQuery = new QueryOptions({
            pageSize: 1,
            columns: [{ name: 'id', filter: id, filterMode: 'equals' }]
        });
        const workAssignment = await this.dataService.get('work_assignments', this.dataQuery, '/work_assignment');
        if (workAssignment && workAssignment[0]) {
            workAssignment[0].init();
            return workAssignment[0];
        }
    }

    /**
     * Save work assignment
     */
    public async saveWorkAssignment(workAssignment: WorkAssignment): Promise<void> {
        this.setProjectUpdated();
        // Update date string value
        // TODO Refactor to more sustainable solution
        workAssignment.updateDate();
        await this.dataService.createOrUpdate('work_assignments', workAssignment);

        // Save items
        for (const item of workAssignment.items) {
            await this.dataService.createOrUpdate('work_assignment_items', item);
        }
    }

    /**
     * Delete work assignment
     * @param workAssignment
     */
    public async deleteWorkAssignment(workAssignment: WorkAssignment): Promise<void> {
        // First delete items
        await workAssignment.init();
        for (const item of workAssignment.items) {
            await this.dataService.delete('work_assignment_items', item.id);
        }

        await this.setProjectUpdated();
        await this.dataService.delete('work_assignments', workAssignment.id);
    }

    /**
     * Delete work assignment item
     * @param workAssignmentItem
     */
    public async deleteWorkAssignmentItem(workAssignmentItem: WorkAssignmentItem): Promise<void> {
        await this.setProjectUpdated();
        await this.dataService.delete('work_assignment_items', workAssignmentItem.id);
    }

    /**
     * Save specialties
     *
     * @param specialities Array of specialties
     */
    public async saveSpecialties(specialities): Promise<void> {
        for (const specialty of specialities) {
            // Update date string value
            // TODO Refactor to more sustainable solution
            specialty.updateDate();
            await this.dataService.createOrUpdate('project_specialties', specialty);
        }
    }

    /**
     * Save activities
     *
     * @param activities Array of activities
     */
    public async saveActivities(activities): Promise<void> {
        for (const activity of activities) {
            activity.updateDate();
            await this.dataService.createOrUpdate('project_activities', activity);
        }
    }

    /**
     * Delete activities
     *
     * @param activities Array of activities
     */
    public async deleteActivities(activities): Promise<void> {
        for (const activity of activities) {
            await this.dataService.delete('project_activities', activity.id);
        }
    }

    /**
     * Get client with id
     * @param id Client id
     */
    public async getClient(id): Promise<void> {
        this.dataQuery = new QueryOptions({
            pageSize: 1,
            columns: [{ name: 'id', filter: id, filterMode: 'equals' }]
        });
        const client = await this.dataService.get('clients', this.dataQuery, '/client');
        this.project.client = client[0];
        this.project.client_id = this.project.client.id;
        this.clientChanged.next(this.project.client);
    }

    /**
     * Create or update inventory_item
     * @param inventoryItem InventoryItem model
     */
    public async createOrUpdateInventoryItem(inventoryItem): Promise<void> {
        await this.setProjectUpdated();
        await this.dataService.createOrUpdate('inventory_items', inventoryItem);
    }

    /**
     * Write default_inventory_items to inventory_items for specific inventory
     *
     * @param inventoryId Number
     */
    public async writeDefaultsToInventoryItems(inventoryId) {
        // TODO: add remote route to get default inventory items and default items
        const inventory = await this.dataService.getById('inventories', inventoryId);
        if (!inventory) {
            return;
        }

        const defaultInventoryItems = await this.dataService.getBy('default_inventory_items', 'default_inventory_id', inventory.default_inventory_id);
        for (const item of defaultInventoryItems) {
            const defaultItem = await this.dataService.getById('default_items', item.default_item_id);
            const inventoryItem = new InventoryItem({
                inventory_id: inventoryId,
                name: defaultItem.name || '',
                volume: defaultItem.volume || 0,
                meterbox: defaultItem.meterbox || 0,
                inventory_type: 'move'
            });

            this.createOrUpdateInventoryItem(inventoryItem);
            const inventoryItemStorage = new InventoryItem({
                inventory_id: inventoryId,
                name: defaultItem.name || '',
                volume: defaultItem.volume || 0,
                meterbox: defaultItem.meterbox || 0,
                inventory_type: 'storage'
            });

            this.createOrUpdateInventoryItem(inventoryItemStorage);
        }

        await this.project.loadInventories();
    }

    /**
     * Delete inventory with id
     * @param id Number
     */
    public async deleteInventoryItem(id): Promise<void> {
        await this.setProjectUpdated();
        await this.dataService.delete('inventory_items', id);
        await this.project.loadInventories();
    }

    /**
     * Delete inventory with id and assocciated inventory_items
     *
     * @param id Number
     */
    public async deleteInventory(id): Promise<void> {
        await this.setProjectUpdated();
        await this.dataService.delete('inventories', id);

        this.dataQuery = new QueryOptions({
            pageSize: this.maxInventoryItems,
            columns: [{ name: 'inventory_id', filter: id, filterMode: 'equals' }]
        });
        this.result = await this.dataService.get('inventory_items', this.dataQuery, '/inventories');
        this.result.forEach(inventory => {
            this.dataService.delete('inventory_items', inventory.id);
        });

        await this.project.loadInventories();
        this.inventoryDeleted.next();
    }

    /**
     * Save new inventory
     * In addition write default_inventory_items to inventory_items
     *
     * @param inventory Inventory model
     */
    public async saveNewInventory(inventory): Promise<void> {
        await this.setProjectUpdated();
        const newInventoryId = await this.dataService.createOrUpdate('inventories', inventory);

        if (inventory.default_inventory_id != null) {
            await this.writeDefaultsToInventoryItems(newInventoryId);
        } else {
            await this.project.loadInventories();
        }

        this.inventoryAdded.next(newInventoryId);
    }

    /**
     * Update inventory
     *
     * @param inventory Inventory model
     */
    public async updateInventory(inventory): Promise<void> {
        await this.setProjectUpdated();
        await this.dataService.createOrUpdate('inventories', inventory);
    }

    /**
     * Get default inventories
     */
    public async getDefaultInventories(): Promise<void> {
        const rooms = [];
        this.dataQuery = new QueryOptions({
            pageSize: this.maxListItems,
            columns: [{ name: 'type', filter: this.project.type, filterMode: 'equals' }]
        });
        this.result = await this.dataService.get('default_inventories', this.dataQuery, '/default-inventory/list');
        this.result.forEach(item => {
            rooms.push({ label: item.name, value: item.id });
        }, this);

        this.defaultInventoriesLoaded.next(rooms);
    }

    /**
     * Update project material
     */
    public async updateMaterial(projectMaterial: ProjectMaterial): Promise<void> {
        await this.setProjectUpdated();
        await this.dataService.createOrUpdate('project_materials', projectMaterial);
        this.materialChanged.next();
    }

    /**
     * Calculates total volume of all inventories for the current project
     *
     * @param inventoryType: string
     * @return number
     */
    public calculateVolume(inventoryType: string = 'move'): number {
        let volumeTotal = 0;

        if (this.project.inventories) {
            for (const inventory of this.project.inventories) {
                if (inventory) {
                    let volume = 0;
                    let roomVolume = 0;

                    if (inventory.items) {
                        inventory.items.filter((item) => item.inventory_type === inventoryType).forEach(item => {
                            volume += (item.amount * item.volume) || 0;
                        });

                        inventory.items.forEach(item => {
                            roomVolume += (item.amount * item.volume) || 0;
                        });

                        volumeTotal += volume;
                        inventory.volume = Math.round(volume * 100) / 100;
                        inventory.roomVolume = Math.round(roomVolume * 100) / 100;
                    }
                }
            }
        }

        return Math.round(volumeTotal * 100) / 100;
    }

    /**
     * Calculate packing total
     *
     * @return number
     */
    public calculatePackingTotal(): number {
        if (!this.project.inventories) {
            return 0;
        }

        let packingTotal = 0;
        for (const inventory of this.project.inventories) {
            if (inventory.packing_amount && Number(inventory.packing_amount)) {
                packingTotal += +inventory.packing_amount;
            }
        }

        return +packingTotal;
    }

    /**
     * Calculate assembly total
     *
     * @return number
     */
    public calculateAssemblyTotal(): number {
        if (!this.project.inventories) {
            return 0;
        }

        let assemblyTotal = 0;
        for (const inventory of this.project.inventories) {
            if (inventory.items) {
                for (const inventoryItem of inventory.items) {
                    assemblyTotal += +(inventoryItem.assemble ? 0.5 : 0) + +(inventoryItem.disassemble ? 0.5 : 0);
                }

                assemblyTotal += +inventory.assembly_amount;
            }
        }

        return +assemblyTotal;
    }

    /**
     * Calculate meterbox total
     *
     * @param inventoryType: string
     * @return number
     */
    public calculateMeterboxTotal(inventoryType: string = 'move'): number {
        if (!this.project.inventories) {
            return 0;
        }

        let meterboxTotal = 0;
        for (const inventory of this.project.inventories) {
            const items = inventory.items.slice().filter((item) => item.inventory_type === inventoryType);

            for (const inventoryItem of items) {
                meterboxTotal += (+inventoryItem.amount * +inventoryItem.meterbox) || 0;
            }
        }

        return +meterboxTotal;
    }

    /**
     * Check if the given input is a number; otherwise return 0
     * @param input: any
     */
    public checkIfNumber(input: any): number {
        if (!Number(input)) {
            try {
                /** Parse string to float, and format to 2 decimals */
                let result = parseFloat(input);
                return Math.round(result * 100) / 100;
            } catch {
                return 0;
            }
        }

        return Number(input) || 0;
    }
}
