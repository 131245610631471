import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ApiService } from '@blueprint/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { Address } from '@domain/models/address.model';
import { DataService, QueryOptions } from '@shared/services/data.service';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs';
import * as toastr from 'toastr';
import 'rxjs/add/operator/takeUntil';

@Component({
  selector: 'app-inventory-address-detail',
  templateUrl: 'address-detail.component.html'
})
export class InventoryAddressDetailComponent implements OnInit, OnDestroy {
  public form;
  public errors: any = {};
  public result;
  public showErrors = false;
  public addressTypes: any = [];
  public address = new Address({});
  public project = new Project({});
  public routeAddressId;
  public isInvoiceAddressType = false;
  public invoiceAddressTypeId: number;

  private subscriptionAddressLoaded: Subscription;
  private destroy$ = new Subject<boolean>();

  public mode = {
    isAdd: true
  };

  public constructor(
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private projectService: ProjectService,
    private formBuilder: FormBuilder) {
    this.invoiceAddressTypeId = null;
  }

  public ngOnInit(): any {
    this.initForm();
    this.loadLists();

    this.project = this.projectService.getProject();
    this.initForm();

    // Get id of address to edit by route params
    this.route.params.subscribe(params => {
      this.routeAddressId = params['id'];

      if (this.routeAddressId) {
        this.mode.isAdd = false;
        this.projectService.getAddress(this.routeAddressId);
        this.subscriptionAddressLoaded = this.projectService.addressLoaded.subscribe((address) => {
          this.address = address;
          this.initForm();
        });
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control(this.address.id),
      project_id: this.formBuilder.control(this.project.id || null),
      address_type_id: this.formBuilder.control(this.address.address_type_id || null, Validators.required),
      street: this.formBuilder.control(this.address.street, Validators.required),
      housenumber: this.formBuilder.control(this.address.housenumber, Validators.required),
      housenumber_add: this.formBuilder.control(this.address.housenumber_add),
      zipcode: this.formBuilder.control(this.address.zipcode, Validators.required),
      city: this.formBuilder.control(this.address.city, Validators.required),
      country: this.formBuilder.control(this.address.country || 'Nederland', Validators.required),
      type: this.formBuilder.control(this.address.address_type_id),
      floor: this.formBuilder.control(this.address.floor),
      accessible_with_max: this.formBuilder.control(this.address.accessible_with_max),
      distance_to_building: this.formBuilder.control(this.address.distance_to_building),
      parking_charge: this.formBuilder.control(this.address.parking_charge),
      lat: this.formBuilder.control(this.address.lat),
      lon: this.formBuilder.control(this.address.lon),
      email: this.formBuilder.control(this.address.email, Validators.email),
    });

    this.isInvoiceAddressType = this.address.address_type_id === this.invoiceAddressTypeId;
    this.updateFormValidation();

    this.form.controls.address_type_id
      .valueChanges
      .distinctUntilChanged()
      .takeUntil(this.destroy$)
      .subscribe((value) => {
        this.isInvoiceAddressType = value === this.invoiceAddressTypeId;
        this.updateFormValidation();
      });
  }

  public async onAddressChange(): Promise<void> {
    const zipcode = this.form.value.zipcode;
    const housenumber = this.form.value.housenumber;
    const street = this.form.value.street;
    const city = this.form.value.city;

    if (!zipcode || !housenumber) {
      return;
    }

    if (street || city) {
      return;
    }

    const result = await this.api
      .post('/address/search', {
        zipcode: this.form.value.zipcode,
        housenumber: this.form.value.housenumber
      })
      .toPromise();

    if (!result) {
      return;
    }

    // Update street and city values
    this.form.patchValue({
      street: result.street,
      city: result.city
    });
  }

  public onSubmit(): void {
    if (this.form.valid) {
      this.address = this.form.value;
      this.projectService.saveAddress(this.address);
      this.onCloseClick();
    } else {
      this.showErrors = true;
    }
  }

  public onCloseClick(): void {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/address');
  }

  private async loadLists(): Promise<void> {
    this.result = await this.dataService.get('address_types', new QueryOptions(), '/address-type/list');
    this.result.forEach((item) => {

      // @todo Refactor to be less ugly, this is nessecary because -facturatieadres- in DB is ID #3, and in frontend it's #5 
      if (item.name === 'Facturatie adres') {
        this.invoiceAddressTypeId = item.id;
      }

      this.addressTypes.push({ label: item.name, value: item.id });
    }, this);
  }

  private updateFormValidation(): void {
    if (this.isInvoiceAddressType) {
      this.form.get('street').clearValidators();
      this.form.get('street').updateValueAndValidity();

      this.form.get('zipcode').clearValidators();
      this.form.get('zipcode').updateValueAndValidity();

      this.form.get('housenumber').clearValidators();
      this.form.get('housenumber').updateValueAndValidity();

      this.form.get('country').clearValidators();
      this.form.get('country').updateValueAndValidity();

      this.form.get('city').clearValidators();
      this.form.get('city').updateValueAndValidity();

      this.form.get('email').setValidators([Validators.email, Validators.required]);
      this.form.get('email').updateValueAndValidity();
    } else {
      this.form.get('street').setValidators(Validators.required);
      this.form.get('street').updateValueAndValidity();

      this.form.get('zipcode').setValidators(Validators.required);
      this.form.get('zipcode').updateValueAndValidity();

      this.form.get('housenumber').setValidators(Validators.required);
      this.form.get('housenumber').updateValueAndValidity();

      this.form.get('country').setValidators(Validators.required);
      this.form.get('country').updateValueAndValidity();

      this.form.get('city').setValidators(Validators.required);
      this.form.get('city').updateValueAndValidity();

      this.form.get('email').clearValidators();
      this.form.get('email').updateValueAndValidity();
    }
  }
}
