import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MenuItem } from 'primeng/primeng';
import { ProjectService } from '@shared/services/project.service';
import { Project } from '@domain/models/project.model';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'app-inventory-flow',
  templateUrl: 'inventory-flow.component.html',
  styleUrls: ['./inventory-flow.component.scss']
})
export class InventoryFlowComponent implements OnInit {
  public currentIndex = 0;
  public routes: MenuItem[] = [
    { routerLink: 'client', label: 'Klant' },
    { routerLink: 'address', label: 'Adressen' },
    { routerLink: 'contact', label: 'Contactpersonen' },
    { routerLink: 'inventory', label: 'Inventarisatie' },
    { routerLink: 'options', label: 'Opties' },
    { routerLink: 'overview', label: 'Overzicht' },
    { routerLink: 'quotation', label: 'Offerte' },
    { routerLink: 'material', label: 'Materialen' },
    { routerLink: 'work-assignment', label: 'Werkopdracht' }
  ];

  constructor(private router: Router, private route: ActivatedRoute, private projectService: ProjectService, private user: UserService) {
    // Remove quotation route for executor
    if (this.user.hasRole('executor')) {
      this.routes = this.routes.filter(r => r.routerLink !== 'quotation');
    }
  }

  ngOnInit(): any {
    this.processRouteParams();
    this.setCurrentIndex();

    // Listen to route changes to update current index
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setCurrentIndex();
      }
    });
  }

  next() {
    if (this.currentIndex < this.routes.length) {
      this.router.navigate([this.routes[this.currentIndex + 1].routerLink], { relativeTo: this.route });
    }
  }

  previous() {
    if (this.currentIndex > 0) {
      this.router.navigate([this.routes[this.currentIndex - 1].routerLink], { relativeTo: this.route });
    }
  }

  save() {
    // Reload page
    this.router.navigate([this.routes[this.currentIndex].routerLink], { relativeTo: this.route });
  }

  // Read project route id and load project in case of page refresh
  processRouteParams() {
    this.route.params.subscribe(params => {
      const projectId = params['project'];
      this.projectService.loadProject(projectId);
    });
  }

  // Set the index according to url
  setCurrentIndex() {
    this.routes.forEach((route, index) => {
      if (this.router.url.includes(route.routerLink)) {
        this.currentIndex = index;
      }
    });
  }
}
