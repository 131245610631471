import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/observable/fromEvent';

import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';

import { environment } from '@environments/environment';

// Emendis Blueprint
import { EmendisBlueprintModule } from '@blueprint/emendis-blueprint.module';

import { TableModule } from 'primeng/table';

// App services
import { AuthGuard } from '@blueprint/auth/auth.guard';
import { AuthService } from '@blueprint/auth/auth.service';

import { UserService } from '@shared/services/user.service';
import { DataService } from '@shared/services/data.service';
import { SynchronisationService } from '@shared/services/synchronisation.service';

// App modules/components
import { Store } from '@domain/store';
import { LayoutsModule } from '@features/gui/layouts/layouts.module';
import { LoginModule } from '@features/login/login.module';
import { UserModule } from '@features/user/user.module';
import { ProjectModule } from '@features/project/project.module';
import { InventoryModule } from '@features/inventory/inventory.module';
import { SettingsModule } from '@features/settings/settings.module';
import { SharedModule } from '@shared/shared.module';
import { InventoryProjectComponent } from '@features/inventory/project/project-detail.component';

import { ProjectGuard } from '@shared/guards/project.guard';
import { UserGuard } from '@shared/guards/user.guard';
import { ClientGuard } from '@shared/guards/client.guard';
import { AddressGuard } from '@shared/guards/address.guard';
import { ContactGuard } from '@shared/guards/contact.guard';
import { ContactListGuard } from '@shared/guards/contact-list.guard';
import { OptionsGuard } from '@shared/guards/options.guard';
import { InventoryGuard } from '@shared/guards/inventory.guard';
import { QuotationGuard } from '@shared/guards/quotation.guard';
import { BootstrapGuard } from '@shared/guards/bootstrap.guard';
import { ProjectOverviewComponent } from '@features/project/project-overview.component';
import { SignaturePadModule } from 'angular2-signaturepad';

/** Sentry init */
import * as Raven from 'raven-js';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

if (environment.production) {
  Raven.config(
    'https://8f8967473439457489231f2dd674a14e@sentry.emendistest.nl/2'
  ).install();
}

export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    if (environment.production) {
      Raven.captureException(err);
    } else {
      throw err;
    }
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpModule,
    LayoutsModule,
    SharedModule,
    LoginModule,
    UserModule,
    ProjectModule,
    SettingsModule,
    InventoryModule,
    SignaturePadModule,
    TableModule,
    HttpClientModule,
    RouterModule.forRoot(ROUTES, { enableTracing: false }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: ErrorHandler, useClass: RavenErrorHandler },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    ProjectGuard,
    ClientGuard,
    UserGuard,
    AddressGuard,
    ContactGuard,
    ContactListGuard,
    OptionsGuard,
    AuthGuard,
    InventoryGuard,
    QuotationGuard,
    BootstrapGuard,
    AuthService,
    DataService,
    UserService,
    SynchronisationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}