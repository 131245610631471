import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import * as toastr from 'toastr';
import { ApiService } from '@blueprint/services/api.service';
import { ConfirmationService } from 'primeng/primeng';
import { OfflineTableOptions, OfflineTableComponent } from '@shared/controls/table/offline-table.component';
import { OnlineTableOptions, OnlineTableComponent } from '@shared/controls/table/online-table.component';
import { Router } from '@angular/router';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ProjectService } from '@shared/services/project.service';
import { Subscription } from 'rxjs/Subscription';
import { Project } from '@domain/models/project.model';

@Component({
  selector: 'app-project-overview',
  templateUrl: 'project-overview.component.html'
})
export class ProjectOverviewComponent implements OnInit, OnDestroy {
  @ViewChild('projectSearchTable') projectSearchTable: OnlineTableComponent;
  @ViewChild('projectOpenTable') projectOpenTable: OfflineTableComponent;

  public projectOpenTableOptions: OfflineTableOptions;
  public projectSearchTableOptions: OnlineTableOptions;

  private subscriptionSynchronisingCompleted: Subscription;

  public constructor(
    private api: ApiService,
    private projectService: ProjectService,
    private synchronisationService: SynchronisationService,
    private confirmationService: ConfirmationService,
    private router: Router
  ) {
  }

  public async ngOnInit(): Promise<any> {
    this.loadOpenProjectTable();

    // Always synchronise when opening project overview
    await this.synchronisationService.synchronise();
    await this.loadSearchProjectTable();

    this.subscriptionSynchronisingCompleted = this.synchronisationService.SynchronisingCompleted.subscribe(_ => {
      this.loadOpenProjectTable();
    });
  }

  public ngOnDestroy(): void {
    if (this.subscriptionSynchronisingCompleted) {
      this.subscriptionSynchronisingCompleted.unsubscribe();
    }
  }

  public loadOpenProjectTable(): void {
    this.projectOpenTableOptions = new OfflineTableOptions({
      columns: [
        { title: 'Type', name: 'projectType' },
        { title: 'Referentie', name: 'reference_nr' },
        { title: 'Status', name: 'status' },
        { title: 'Accountbeheerder', name: 'accountmanagerName' }
      ],
      withDelete: true,
      url: '/projects',
      tableName: 'projects',
      search: false,
      rowDataTransformer: (rows) => {
        for (const row of rows) {
          row.status = Project.getStatusName(row.status);
        }

        return rows;
      },
    });
  }

  public async loadSearchProjectTable(): Promise<void> {
    this.projectSearchTableOptions = new OnlineTableOptions({
      columns: [
        {
          title: 'Type', name: 'type', filter: {
            type: 'select',
            options: [
              { label: 'Alles', value: '' },
              { label: 'Particulier', value: 'private' },
              { label: 'Zakelijk', value: 'business' }
            ]
          }
        },
        { title: 'Referentie', name: 'reference_nr', filter: { global: true } },
        {
          title: 'Status', name: 'status', filter: {
            type: 'select',
            options: [
              { label: 'Alles', value: '' },
              { label: 'Nieuw', value: 'new' },
              { label: 'Pending', value: 'pending' },
              { label: 'Booked', value: 'booked' },
              { label: 'Lost', value: 'lost' },
              { label: 'Inactive', value: 'inactive' },
            ],
          }
        },
        { title: 'Accountbeheerder', name: 'accountmanager', filter: { global: true, field: 'accountmanagers.name' } }
      ],
      endpoint: '/projects',
      search: true,
      withDelete: true,
      rowDataTransformer: this.transformRows.bind(this),
    });

    // Set default status filter to 'all'
    if (this.projectSearchTable) {
      this.projectSearchTable.dataTable.filters['status'] = {value: ''};
    }
  }

  public async onOpenProjectTableRowClick(data: any) {
    this.router.navigateByUrl('/admin/project/' + data.id + '/client');
  }

  public async onSearchProjectTableRowClick(data: any) {
    // Load project data from server and then navigate to detail view
    this.projectSearchTable.loading = true;
    await this.synchronisationService.loadSingleProjectData(data.id);
    this.projectSearchTable.loading = false;
    this.router.navigateByUrl('/admin/project/' + data.id + '/client');
  }

  public async addNewProject() {
    const project = await this.projectService.newProject();
    this.router.navigateByUrl(`/admin/project/${project.id}/client`);
  }

  public async closeOpenProjects() {
    if (!this.projectOpenTable.selectedRows || this.projectOpenTable.selectedRows.length === 0) {
      toastr.warning('Geen projecten geselecteerd');
      return;
    }

    this.confirmationService.confirm({
      message: 'Wilt u de geselecteerde projecten synchroniseren en sluiten?',
      header: 'Bevestiging',
      icon: 'fa fa-question-circle',
      accept: async () => {
        this.projectOpenTable.loading = true;
        const closeProjectIds: number[] = this.projectOpenTable.selectedRows.map(row => row.id);
        const result: boolean = await this.synchronisationService.syncToBackend(true);
        if (!result) {
          toastr.error('Projecten synchroniseren sluiten mislukt. Controleer uw verbinding.');
          return;
        }

        // Retrieve open projects except selectedrows
        for (const row of this.projectOpenTable.rows) {
          if (closeProjectIds.indexOf(row.id) === -1) {
            await this.synchronisationService.loadSingleProjectData(row.id);
          }
        }

        this.loadOpenProjectTable();
        this.projectSearchTable.dataTable.reset();
        this.projectOpenTable.loading = false;

        await this.synchronisationService.synchronise();
        await this.loadSearchProjectTable();

        this.router.navigateByUrl('/admin/settings').then(_ => {
          this.router.navigateByUrl('/');
        });
      }
    });
  }

  private transformRows(rows: Array<any>): any {
    // Determine open projects
    const openProjects = this.projectOpenTable.rows;
    const openProjectGuids = openProjects ? openProjects.map(project => project.id) : [];
    const deleteRows = [];
    for (const row of rows) {
      // Remove rows which are already opened
      if (openProjectGuids.includes(row.id)) {
        deleteRows.push(row);
      }

      row.client = row.client ? row.client.name : '';
      row.accountmanager = row.accountmanager ? row.accountmanager.name : '';
      row.status = Project.getStatusName(row.status);
    }

    for (const row of deleteRows) {
      rows.splice(rows.indexOf(row), 1);
    }

    return rows;
  }
}
