import { Routes } from '@angular/router';

import { AuthGuard } from '@blueprint/auth/auth.guard';

import { UserGuard } from '@shared/guards/user.guard';
import { ClientGuard } from '@shared/guards/client.guard';
import { AddressGuard } from '@shared/guards/address.guard';
import { ContactGuard } from '@shared/guards/contact.guard';
import { ContactListGuard } from '@shared/guards/contact-list.guard';
import { OptionsGuard } from '@shared/guards/options.guard';
import { InventoryGuard } from '@shared/guards/inventory.guard';
import { QuotationGuard } from '@shared/guards/quotation.guard';
import { BootstrapGuard } from '@shared/guards/bootstrap.guard';

import { LoginComponent } from '@features/login/login.component';
import { UserOverviewComponent } from '@features/user/user-overview.component';
import { UserDetailComponent } from '@features/user/user-detail.component';
import { LoginLayoutComponent } from '@features/gui/layouts/loginLayout.component';
import { FooterNavigationLayoutComponent } from '@features/gui/layouts/footerNavigationLayout.component';
import { ProjectOverviewComponent } from '@features/project/project-overview.component';
import { InventoryFlowComponent } from '@features/inventory/inventory-flow.component';
import { InventoryAddressDetailComponent } from '@features/inventory/address/address-detail.component';
import { InventoryOptionsDetailComponent } from '@features/inventory/options/options-detail.component';
import { InventoryQuotationComponent } from '@features/inventory/quotation/quotation.component';
import { InventoryWorkAssignmentListComponent } from '@features/inventory/work-assignment/work-assignment-list.component';
import { InventoryWorkAssignmentDetailComponent } from '@features/inventory/work-assignment/work-assignment-detail.component';
import { InventoryBoardComponent } from '@features/inventory/inventory/inventory-board.component';
import { PopupLayoutComponent } from '@features/gui/layouts/popupLayout.component';
import { InventoryContactsDetailComponent } from '@features/inventory/contact/contact-detail.component';
import { InventoryProjectComponent } from '@features/inventory/project/project-detail.component';
import { InventoryClientComponent } from '@features/inventory/client/client.component';
import { InventoryAddressListComponent } from '@features/inventory/address/address-list.component';
import { InventoryClientSearchComponent } from '@features/inventory/client/client-search.component';
import { InventoryAddComponent } from '@features/inventory/inventory/add/inventory-add.component';
import { InventoryContactListComponent } from '@features/inventory/contact/contact-list.component';
import { SettingsOverviewComponent } from '@features/settings/settings-overview.component';
import { MaterialComponent } from '@features/inventory/material/material.component';
import { InventoryOverviewComponent } from '@features/inventory/overview/overview.component';
import { InventoryAddItemComponent } from '@features/inventory/inventory/add-item/inventory-add-item.component';

export const ROUTES: Routes = [

  // Admin section
  {
    path: 'admin',
    canActivate: [AuthGuard, BootstrapGuard],
    canActivateChild: [AuthGuard],
    component: FooterNavigationLayoutComponent,
    children: [

      // Main redirect
      { path: '', redirectTo: 'projects', pathMatch: 'full' },

      // App views
      { path: 'settings', component: SettingsOverviewComponent },
      { path: 'projects', component: ProjectOverviewComponent },
      { path: 'users', component: UserOverviewComponent, canActivate: [UserGuard] },
      {
        path: 'project/:project', component: InventoryFlowComponent, children: [
          { path: 'client', component: InventoryClientComponent, canDeactivate: [ClientGuard] },
          { path: 'address', component: InventoryAddressListComponent, canDeactivate: [AddressGuard] },
          { path: 'contact', component: InventoryContactListComponent, canDeactivate: [ContactGuard] },
          { path: 'options', component: InventoryOptionsDetailComponent, canActivate: [ContactListGuard], canDeactivate: [OptionsGuard] },
          { path: 'inventory', component: InventoryBoardComponent, canActivate: [ContactListGuard], canDeactivate: [InventoryGuard] },
          { path: 'overview', component: InventoryOverviewComponent, canActivate: [ContactListGuard] },
          { path: 'material', component: MaterialComponent, canActivate: [ContactListGuard] },
          { path: 'quotation', component: InventoryQuotationComponent, canActivate: [QuotationGuard, ContactListGuard] },
          { path: 'work-assignment', component: InventoryWorkAssignmentListComponent, canActivate: [ContactListGuard] },
          { path: 'work-assignment/:id', component: InventoryWorkAssignmentDetailComponent, canActivate: [ContactListGuard] },
        ]
      },
    ]
  },

  {
    path: 'admin',
    canActivate: [AuthGuard, BootstrapGuard],
    canActivateChild: [AuthGuard],
    component: PopupLayoutComponent,
    outlet: 'popup',
    children: [
      { path: 'project/address/add', component: InventoryAddressDetailComponent },
      { path: 'project/address/:id', component: InventoryAddressDetailComponent },
      { path: 'project/contact/add', component: InventoryContactsDetailComponent },
      { path: 'project/contact/:id', component: InventoryContactsDetailComponent },
      { path: 'project/client-search', component: InventoryClientSearchComponent },
      { path: 'project/inventory/add', component: InventoryAddComponent },
      { path: 'project/inventory/add-item', component: InventoryAddItemComponent },
      { path: 'user/:id', component: UserDetailComponent, canActivate: [UserGuard] },
    ]
  },

  { path: '', redirectTo: 'admin/projects', pathMatch: 'full' },
  {
    path: '', component: LoginLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent },
    ]
  },

  // Handle all other routes
  { path: '**', redirectTo: 'admin' }
];
