import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-inventory-item',
  templateUrl: 'inventory-item.component.html',
  styleUrls: ['../inventory.scss']
})
export class InventoryItemComponent implements OnInit {
  @Input('inventoryItem') inventoryItem;
  @Output('inventoryItemChanged') inventoryItemChanged = new EventEmitter;
  @Output('inventoryItemDeleted') inventoryItemDeleted = new EventEmitter;

  constructor() {
  }

  public ngOnInit() {
    if (!this.inventoryItem.amount || this.inventoryItem.amount < 0) {
      this.inventoryItem.amount = 0;
    }
  }

  private addAmount() {
    this.inventoryItem.amount += this.getIncrement();
    this.inventoryItemChanged.emit(this.inventoryItem);
  }

  private removeAmount() {
    if (this.inventoryItem.amount === 0) {
      return;
    }
    this.inventoryItem.amount -= this.getIncrement();
    this.inventoryItemChanged.emit(this.inventoryItem);
  }

  /**
   * Determines the increment/decrement value of the inventory item
   */
  private getIncrement() {
    // Increment boxes by 5. This should be set in the default item,
    // but the current database architecture is not sufficient
    if (this.inventoryItem.name === 'Verhuisdozen' || this.inventoryItem.name === 'Boekendozen') {
      return 5;
    }
    return 1;
  }

  private onDetailsChange(inventoryItem) {
    this.inventoryItemChanged.emit(inventoryItem);
  }

  private onInventoryItemDelete(inventoryItem) {
    this.inventoryItemDeleted.emit(inventoryItem);
  }
}
