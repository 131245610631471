import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/primeng';
import { ApiService } from '@blueprint/services/api.service';
import { OfflineTableOptions, OfflineTableComponent } from '@shared/controls/table/offline-table.component';
import { DataService } from '@shared/services/data.service';
import { ProjectService } from '@shared/services/project.service';
import { Project } from '@domain/models/project.model';
import { Address } from '@domain/models/address.model';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-inventory-address-list',
  templateUrl: 'address-list.component.html'
})
export class InventoryAddressListComponent implements OnInit, OnDestroy {
  @ViewChild(OfflineTableComponent) table: OfflineTableComponent;
  private addAddress = false;
  public tableOptions: OfflineTableOptions;
  public project = new Project({});

  private subscriptionProjectLoaded: Subscription;
  private subscriptionAddressAdded: Subscription;

  public constructor(private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private dataService: DataService,
    private projectService: ProjectService) { }

  ngOnInit(): any {
    this.project = this.projectService.getProject();
    this.setTableOptions();

    // In case project was loaded
    this.projectService.projectLoaded.subscribe(project => {
      this.project = project;
      this.setTableOptions();
    });

    // In case address was added (from popup)
    this.projectService.addressAdded.subscribe(contact => {
      this.table.onChangeTable();
    });
  }

  ngOnDestroy() {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }
    if (this.subscriptionAddressAdded) {
      this.subscriptionAddressAdded.unsubscribe();
    }
  }

  public cloneAddress(row: Address) {
    delete row.id;
    this.projectService.saveAddress(row);
  }

  private setTableOptions() {
    this.tableOptions = new OfflineTableOptions({
      paging: false,
      search: false,
      columns: [
        { title: 'Type', name: 'type' },
        { title: 'Adres', name: 'address' }
      ],
      actions: [
        { title: 'Adres dupliceren', action: 'cloneAddress', icon: 'fa-clone' }
      ],
      noResultsMessage: 'Er zijn nog geen adressen aangemaakt',
      withDelete: true,
      url: '/address',
      tableName: 'addresses',
      filtering: { hiddenColumns: [{ name: 'project_id', filter: this.project.id || 0, filterMode: 'equals' }] },
      rowDataTransformer: (rows) => {
        for (const row of rows) {
          if (row.type === 'Facturatie adres') {
            row.address = row.email;
          } else {
            row.address = `${row.street || ''} ${row.housenumber || ''}${row.housenumber_add || ''},
            ${row.zipcode || ''} ${row.city || ''}, ${row.country || ''}`;
          }
        }
        return rows;
      },
    });
  }

  private onRowClick(data: any) {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/address(popup:admin/project/address/' + data.id + ')');
  }

  private onActionClick(data: any) {
    this[data.action](data.row);
  }

  private onAddClick() {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/address(popup:admin/project/address/add)');
  }

  private onDeleteClick() {
    this.confirmationService.confirm({
      message: 'Wilt u de geselecteerde adressen verwijderen?',
      header: 'Bevestiging',
      icon: 'fa fa-question-circle',
      accept: () => {
        this.table.selectedRows.forEach(row => {
          this.projectService.deleteAddress(row.id);
        });
        this.table.onChangeTable();
      }
    });
  }
}
