import { DomainModel } from '@domain/domain.model';
import { Project } from '@domain/models/project.model';
import { QuotationTask } from '@domain/models/quotation-task.model';
import { QuotationMaterial } from '@domain/models/quotation-material.model';

import * as uuid from 'uuid/v4';

export class Quotation extends DomainModel {
  // Configuration
  public entity = 'quotation';
  public table = 'quotations';
  public schema = 'id, remote_id, project_id, reference';
  public sync = true;
  public id: string;

  // Fields
  public project_id: string;
  public reference?: string;
  public status?: string;
  public calculated_volume: number = 0;
  public total_volume: number = 0;
  public estimated_distance: number = 0;
  public distance: number = 0;
  public estimated_distance_km: number = 0;
  public distance_km: number = 0;
  public base_price: number = 0;
  public packing_fragile_price?: number;
  public unpacking_fragile_price?: number;
  public packing_complete_price?: number;
  public remove_lights_curtains_price?: number;
  public washing_machine_brace_price?: number;
  public piano_grand_piano_price?: number;
  public waterbed_relocation_price?: number;
  public assembly_price?: number;
  public packing_price?: number;
  public guarantee_certificate_price: number = 55; // Price is 0% VAT!
  public handyman_certificate_price?: number;
  public storage_week_text?: number;
  public storage_week_total_price?: number;
  public storage_handling_price?: number;
  public storage_insurance_price?: number;
  public storage_insurance_total_price?: number;
  public storage_value_price?: number;
  public parking_waiver_price?: number;
  public custom_option_1_name?: string;
  public custom_option_2_name?: string;
  public custom_option_3_name?: string;
  public custom_option_1_price?: number;
  public custom_option_2_price?: number;
  public custom_option_3_price?: number;

  public floor_surcharge?: number;
  public elevator_surcharge?: number;
  public piano_safe_surcharge?: number;

  public subtotal_price?: number;
  public vat_price?: number;
  public total_price?: number;

  public pin_payment?: boolean;

  public materials: QuotationMaterial[] = [];
  public tasks: QuotationTask[] = [];

  public invoice_name?: string;
  public invoice_attn?: string;

  public signatureClientDate?: Date;
  public signatureValuatorDate?: Date;

  public signature_client_date?: string;
  public signature_valuator_date?: string;

  public signature_client_image?: string;
  public signature_valuator_image?: string;

  public manually_changed_base_price: boolean = false;

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public async init() {
    this.signatureClientDate = this.signature_client_date
      ? this.parseDate(this.signature_client_date)
      : null;
    this.signatureValuatorDate = this.signature_valuator_date
      ? this.parseDate(this.signature_valuator_date)
      : null;

    if (!this.id) {
      return;
    }

    // Set relations
    const quotationMaterials = await QuotationMaterial.query
      .where('quotation_id')
      .equals(this.id)
      .toArray();
    this.materials = [];
    for (const quotationMaterial of quotationMaterials) {
      await quotationMaterial.init();
      this.materials.push(quotationMaterial);
    }

    const quotationTasks = await QuotationTask.query
      .where('quotation_id')
      .equals(this.id)
      .toArray();
    this.tasks = [];
    for (const quotationTask of quotationTasks) {
      await quotationTask.init();
      this.tasks.push(quotationTask);
    }
  }

  public getData(): any {
    return {
      id: this.id,
      project_id: this.project_id,
      reference: this.reference,
      status: this.status,
      calculated_volume: this.calculated_volume,
      total_volume: this.total_volume,
      estimated_distance: this.estimated_distance,
      distance: this.distance,
      estimated_distance_km: this.estimated_distance_km,
      distance_km: this.distance_km,
      base_price: this.base_price,
      packing_fragile_price: this.packing_fragile_price,
      unpacking_fragile_price: this.unpacking_fragile_price,
      packing_complete_price: this.packing_complete_price,
      assembly_price: this.assembly_price,
      packing_price: this.packing_price,
      remove_lights_curtains_price: this.remove_lights_curtains_price,
      washing_machine_brace_price: this.washing_machine_brace_price,
      piano_grand_piano_price: this.piano_grand_piano_price,
      waterbed_relocation_price: this.waterbed_relocation_price,
      guarantee_certificate_price: this.guarantee_certificate_price,
      handyman_certificate_price: this.handyman_certificate_price,
      storage_week_text: this.storage_week_text,
      storage_week_total_price: this.storage_week_total_price,
      storage_handling_price: this.storage_handling_price,
      storage_insurance_price: this.storage_insurance_price,
      storage_insurance_total_price: this.storage_insurance_total_price,
      storage_value_price: this.storage_value_price,
      parking_waiver_price: this.parking_waiver_price,
      custom_option_1_name: this.custom_option_1_name,
      custom_option_2_name: this.custom_option_2_name,
      custom_option_3_name: this.custom_option_3_name,
      custom_option_1_price: this.custom_option_1_price,
      custom_option_2_price: this.custom_option_2_price,
      custom_option_3_price: this.custom_option_3_price,
      floor_surcharge: this.floor_surcharge,
      elevator_surcharge: this.elevator_surcharge,
      piano_safe_surcharge: this.piano_safe_surcharge,
      subtotal_price: this.subtotal_price,
      vat_price: this.vat_price,
      total_price: this.total_price,
      pin_payment: this.pin_payment,
      invoice_name: this.invoice_name,
      invoice_attn: this.invoice_attn,
      signature_client_image: this.signature_client_image,
      signature_valuator_image: this.signature_valuator_image,
      signature_client_date: this.formatDate(this.signatureClientDate),
      signature_valuator_date: this.formatDate(this.signatureValuatorDate),
      manually_changed_base_price: this.manually_changed_base_price || false,
    };
  }

  public updateDate() {
    this.signature_client_date = this.formatDate(this.signatureClientDate);
    this.signature_valuator_date = this.formatDate(this.signatureValuatorDate);
  }
}
