import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { BsDropdownModule } from 'ngx-bootstrap';

import { BasicLayoutComponent } from './basicLayout.component';
import { BlankLayoutComponent } from './blankLayout.component';
import { LoginLayoutComponent } from './loginLayout.component';
import { FooterNavigationLayoutComponent } from './footerNavigationLayout.component';

import { NavigationComponent } from './../navigation/navigation.component';
import { HeaderComponent } from './../header/header.component';
import { FooterComponent } from './../footer/footer.component';
import { PopupLayoutComponent } from './popupLayout.component';

import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    BasicLayoutComponent,
    BlankLayoutComponent,
    LoginLayoutComponent,
    FooterNavigationLayoutComponent,
    NavigationComponent,
    PopupLayoutComponent
  ],
  imports: [
    SharedModule,
    BrowserModule,
    RouterModule,
    BsDropdownModule.forRoot(),
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    BasicLayoutComponent,
    BlankLayoutComponent,
    LoginLayoutComponent,
    FooterNavigationLayoutComponent,
    NavigationComponent
  ]
})

export class LayoutsModule {
}
