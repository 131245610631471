import { DomainModel } from '@domain/domain.model';
import { Project } from '@domain/models/project.model';

export class RelationGroup extends DomainModel {

    // Configuration
    public entity = 'relation-group';
    public table = 'relation_groups';
    public schema = 'id, name, project_id';
    public sync = true;

    // Fields
    public id: string;
    public name: string;
    public project_id?: string;
    public project?: Project;

    // Constructor
    constructor(attributes) {
        super(attributes);
    }
}
