import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Material } from '@domain/models/material.model';
import { ProjectMaterial } from '@domain/models/project-material.model';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-material',
  templateUrl: './material.component.html',
  styleUrls: ['./material.component.scss']
})
export class MaterialComponent implements OnInit, OnDestroy {
  public project = new Project({});
  public materials: Material[];
  public projectMaterials: ProjectMaterial[];

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private projectService: ProjectService) { }

  public ngOnInit(): void {
    this.project = this.projectService.getProject();

    // Load on project
    this.projectService.projectLoaded
      .pipe(takeUntil(this.destroy$))
      .subscribe((project) => {
        this.project = project;
        this.getMaterials();
      });

    this.getMaterials();
  }

  public async getMaterials(): Promise<void> {
    this.projectMaterials = await ProjectMaterial.query
      .where('project_id')
      .equals(this.project.id)
      .toArray();

    const result = await Material.query.toArray();

    /** Sort the materials alphabetically */
    this.materials = result.sort((a: any, b: any) => {
      const textA = a.name.toUpperCase();
      const textB = b.name.toUpperCase();

      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
  }

  public ngOnDestroy() {
    this.destroy$.next(true);
    this.projectService.saveProject();
    this.projectService.setProjectUpdated();
  }

  public getAmount(material: Material): number {
    const item = this.getMaterialItem(material);
    return item ? item.amount : 0;
  }

  public addAmount(material: Material): void {
    const item = this.getMaterialItem(material);
    if (item) {
      item.amount++;
      this.projectService.updateMaterial(item);
    } else {
      const newProjectMaterial = new ProjectMaterial({
        project_id: this.project.id,
        material_id: material.id,
        amount: 1
      });
      this.projectMaterials.push(newProjectMaterial);
      this.projectService.updateMaterial(newProjectMaterial);
    }
  }

  public removeAmount(material: Material): void {
    const item = this.getMaterialItem(material);
    if (item && item.amount > 0) {
      item.amount--;
      this.projectService.updateMaterial(item);
    }
  }

  private getMaterialItem(material: Material): ProjectMaterial {
    return this.projectMaterials.find((value: ProjectMaterial) => {
      return value.material_id === material.id;
    });
  }
}
