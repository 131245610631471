import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';

declare const jQuery: any;

@Component({
  selector: 'app-quotation-signature',
  templateUrl: 'quotation-signature.component.html',
  styleUrls: ['./quotation-signature.component.scss']
})
export class QuotationSignatureComponent implements OnInit {
  @Input('signature') signature;
  @Output('signatureChange') signatureChange = new EventEmitter();
  @ViewChild('modal') modal: any;
  @ViewChild('signaturePad') signaturePad: SignaturePad;

  private signatureSigned = false;
  public signaturePadOptions: Object = {
    'minWidth': 0.5,
    'canvasWidth': (window.innerWidth - (window.innerWidth * 0.1) - 80),
    'canvasHeight': 400,
    'backgroundColor': '#fafafa'
  };

  constructor() {}

  public ngOnInit() {}

  public showForm() {
    this.clearSignature();
    jQuery(this.modal.nativeElement).modal('show');
  }

  public hideForm() {
    jQuery(this.modal.nativeElement).modal('hide');
  }

  private signatureStart() {
    this.signatureSigned = true;
  }

  private clearSignature() {
    this.signaturePad.clear();
    this.signatureSigned = false;
  }

  private onSubmit() {
    this.signatureChange.emit(this.signaturePad.toDataURL());
    this.hideForm();
  }
}
