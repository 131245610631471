import { Component, Input, Output, EventEmitter } from '../../../../../node_modules/@angular/core';

@Component({
  selector: 'em-switch-option',
  templateUrl: 'switch-option.component.html'
})
export class SwitchOptionComponent {
  @Input() name = '';
  @Input() value: any = '';
  @Input() icon = '';
  @Input() selected = false;

  @Output() click = new EventEmitter<any>();

  public constructor() { }

  public onClick() {
    this.click.emit(this);
  }
}
