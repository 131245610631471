import { Directive } from '../../../../../node_modules/@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '../../../../../node_modules/@angular/forms';

@Directive({
  selector: '[appZipcode]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ZipCodeValidatorDirective, multi: true }]
})
export class ZipCodeValidatorDirective implements Validator {

  validate(c: AbstractControl): ValidationErrors {
    if (c.value && c.value !== '') {
      if (c.value.length < 6) {
        return { 'minlength': { value: c.value } };
      } else if (c.value.length > 7) {
        return { 'maxlength': { value: c.value } };
      } else if (!(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i).test(c.value.toLocaleLowerCase())) {
        return { 'zipcode': { value: c.value } };
      }
    }
    return null;
  }

  registerOnValidatorChange(fn: () => void): void {
  }
}
