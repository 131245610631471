import { Injectable, EventEmitter } from '../../../../node_modules/@angular/core';
import { Http, Headers, Response } from '../../../../node_modules/@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import { Subject } from 'rxjs/Subject';
import { environment } from '@environments/environment';

@Injectable()
export class AuthService {
  private authenticated = false;

  constructor(private http: Http) { }

  public login(email: string, password: string) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.http.post(`${environment.baseUrl}/auth/login`,
      { email: email, password: password },
      { headers })
      .map((data: Response) => data.json())
      .map((result: any) => {
        if (result.status === 'ok') {
          localStorage.setItem('token', result.token);

          return true;
        }
        return false;
      })
      .catch(this.handleError);
  }

  public resetPassword(email: string) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.http.post(`${environment.baseUrl}/auth/recovery`,
      { email: email },
      { headers })
      .map((data: Response) => data.json())
      .map((result: any) => {
        return result.status === 'ok';
      })
      .catch(this.handleError);
  }

  public logout() {
    const headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Authorization', `Bearer ${this.getToken()}`);

    return this.http.post(`${environment.baseUrl}/auth/logout`, '', {
      headers
    })
      .map((data: Response) => data.json())
      .map((result: any) => {
        if (result.success) {
          localStorage.removeItem('token');

          return result;
        }

        return false;
      })
      .catch(this.handleError);
  }

  public isAuthenticated(): boolean {
    return !!localStorage.getItem('token');
  }

  public getToken(): string {
    return localStorage.getItem('token');
  }

  private handleError(error: any): Observable<any> {
    return Observable.throw(error);
  }
}
