import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-inventory-item-detail',
  templateUrl: 'inventory-item-details.component.html',
  styleUrls: ['../inventory.scss']
})
export class InventoryItemDetailsComponent implements OnInit, OnDestroy {
  @Input('inventoryItem') inventoryItem;
  @Input('modalId') modalId;
  @Output('inventoryItemChanged') inventoryItemChanged = new EventEmitter;
  @Output('inventoryItemDeleted') inventoryItemDeleted = new EventEmitter;

  private subscriptionImageResize: Subscription;

  public constructor(private ng2ImgMaxService: Ng2ImgMaxService) {}

  public ngOnInit(): any {}

  public ngOnDestroy() {
    if (this.subscriptionImageResize) {
      this.subscriptionImageResize.unsubscribe();
    }
  }

  toggleAssemble(checked: boolean) {
    this.inventoryItem.assemble = checked;
  }

  toggleDisassemble(checked: boolean) {
    this.inventoryItem.disassemble = checked;
  }

  cameraChangeEvent(fileInput: any) {
    if (!fileInput.target.files || fileInput.target.files.length === 0) {
      return;
    }

    const file = fileInput.target.files[0];
    if (!file.type.match(/^image\//)) {
      return;
    }

    this.subscriptionImageResize = this.ng2ImgMaxService.resize([file], 300, 300).subscribe((result) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.inventoryItem.image = reader.result;
      }, false);
      reader.readAsDataURL(result);
    });
  }

  addAmount() {
    this.inventoryItem.amount++;
  }

  removeAmount() {
    if (this.inventoryItem.amount === 0) {
      return;
    }
    this.inventoryItem.amount--;
  }

  deleteItem() {
    this.inventoryItemDeleted.emit(this.inventoryItem);
  }

  closeModal() {
    this.inventoryItemChanged.emit(this.inventoryItem);
  }
}
