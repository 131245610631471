import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { ConfirmationService } from 'primeng/primeng';
import { Project } from '@domain/models/project.model';
import { Address } from '@domain/models/address.model';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ProjectService } from '@shared/services/project.service';
import { DataService, QueryOptions } from '@shared/services/data.service';
import { OfflineTableOptions, OfflineTableComponent } from '@shared/controls/table/offline-table.component';
import { WorkAssignment } from '@domain/models/work-assignment.model';

@Component({
  selector: 'app-inventory-work-assignment-list',
  templateUrl: 'work-assignment-list.component.html'
})
export class InventoryWorkAssignmentListComponent implements OnInit, OnDestroy {
  private project: Project;
  public tableOptions: OfflineTableOptions;
  @ViewChild(OfflineTableComponent) table: OfflineTableComponent;

  private subscriptionProjectLoaded: Subscription;

  constructor(
    private router: Router,
    private confirmationService: ConfirmationService,
    private syncService: SynchronisationService,
    private projectService: ProjectService,
    private dataService: DataService) {
  }

  public ngOnInit() {
    this.project = this.projectService.getProject();
    this.subscriptionProjectLoaded = this.projectService.projectLoaded.subscribe(project => {
      this.project = project;
      this.setTableOptions();
    });
    this.setTableOptions();
  }

  public ngOnDestroy() {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }
  }

  private setTableOptions() {
    this.tableOptions = new OfflineTableOptions({
      paging: false,
      search: false,
      columns: [
        { title: 'Datum', name: 'date' },
        { title: 'Afhaaladres', name: 'pickupAddressName' },
        { title: 'Afleveradres', name: 'deliverAddressName' }
      ],
      noResultsMessage: 'Er zijn nog geen werkopdrachten aangemaakt',
      withDelete: true,
      url: '/work_assignments',
      tableName: 'work_assignments',
      filtering: {
        hiddenColumns: [
          { name: 'project_id', filter: this.project.id || 0, filterMode: 'equals' }
        ],
        sortColumn: 'assignment_date',
        sortOrder: 'asc'
      },
      rowDataTransformer: (rows) => {
        for (const row of rows) {
          row.pickupAddressName = row.pickupAddress ? row.pickupAddress.getDisplayName() : '';
          row.deliverAddressName = row.deliverAddress ? row.deliverAddress.getDisplayName() : '';
          row.date = row.displayDate(row.assignmentDate);
        }
        return rows;
      },
    });
  }

  private onRowClick(data: any) {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/work-assignment/' + data.id);
  }

  private async onAddClick() {
    // Create new work assignment and open detail
    const workAssignment = new WorkAssignment({ project_id: this.project.id });
    workAssignment.setDefaults();
    await this.projectService.saveWorkAssignment(workAssignment);
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/work-assignment/' + workAssignment.id);
  }

  private onDeleteClick() {
    if (!this.table.selectedRows || this.table.selectedRows.length === 0) {
      return;
    }
    this.confirmationService.confirm({
      message: 'Wilt u de geselecteerde werkopdrachten verwijderen?',
      header: 'Bevestiging',
      icon: 'fa fa-question-circle',
      accept: () => {
        this.table.selectedRows.forEach(async row => {
          await this.projectService.deleteWorkAssignment(row);
        });
        this.table.onChangeTable();
      }
    });
  }
}
