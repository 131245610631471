import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProjectService } from '@shared/services/project.service';
import { Project } from '@domain/models/project.model';
import { ConfirmationService } from 'primeng/primeng';
import { Inventory } from '@domain/models/inventory.model';
import { InventoryHeaderComponent } from '@features/inventory/inventory/header/inventory-header.component';
import { Subscription } from 'rxjs/Subscription';
import { Router } from '@angular/router';
import { InventoryItem } from '@domain/models/inventory-item.model';

@Component({
  selector: 'app-inventory-board',
  templateUrl: 'inventory-board.component.html'
})
export class InventoryBoardComponent implements OnInit, OnDestroy {
  @ViewChild('header') header: InventoryHeaderComponent;

  public project = new Project({});
  public inventories;
  public filteredInventoryItems: Array<any>;
  public selectedInventory = new Inventory({});
  public selectedInventoryId = null;
  public volumeTotal: number;
  public assemblyTotal: number;
  public packingTotal: number;
  public meterboxTotal: number;
  public selectedType: string;

  private subscriptionInventoryDeleted: Subscription;
  private subscriptionInventoryAdded: Subscription;
  private subscriptionProjectLoaded: Subscription;
  private subscriptionInventoryItemAdded: Subscription;

  public constructor(
      private projectService: ProjectService,
      private confirmationService: ConfirmationService,
      private router: Router
  ) {
    this.selectedType = 'move';
    this.selectedInventory = null;
  }

  public ngOnInit(): any {
    this.filteredInventoryItems = [];
    this.project = this.projectService.getProject();
    this.inventories = this.project.inventories;

    // When inventory is deleted reset selected id and inventory then reload all inventories
    this.subscriptionInventoryDeleted = this.projectService.inventoryDeleted.subscribe(_ => {
      this.inventories = this.project.inventories;
      this.selectedInventoryId = 0;
      this.selectedType = 'move';
      this.getSelectedInventory();
    });

    this.subscriptionInventoryAdded = this.projectService.inventoryAdded.subscribe(async (inventoryId) => {
      await this.project.loadInventories();
      this.inventories = this.project.inventories;
      this.selectedInventoryId = inventoryId;
      this.selectedType = 'move';
      this.getSelectedInventory();
    });

    // Reload when project changes
    this.subscriptionProjectLoaded = this.projectService.projectLoaded.subscribe((project) => {
      this.project = project;
      this.inventories = this.project.inventories;
    });

    this.subscriptionInventoryItemAdded = this.projectService.inventoryItemAdded.subscribe((inventoryItem) => {
      this.createInventoryItem(this.selectedType, inventoryItem);
    });

    this.calculateTotals();
    this.packingTotal = this.projectService.calculatePackingTotal();

    this.getSelectedInventory();
  }

  /**
   * Set the Inventory type
   *
   * @param type: string
   * @return Promise<void>
   */
  public async setInventoryType(type: string): Promise<void> {
    this.selectedType = type;
    this.getFilteredInventoryItems(type);
  }

  /**
   * Check all inventories which match floor and room (default_inventory)
   */
  public onInventoryChange(inventory) {
    if (!inventory) {
      return;
    }
    this.selectedInventory = inventory;
    this.selectedInventoryId = inventory.id;
    this.getSelectedInventory();
    this.calculateTotals();
    this.packingTotal = this.projectService.calculatePackingTotal();
  }

  /**
   * Search inventory for selected inventory from header
   */
  public getSelectedInventory() {
    this.filteredInventoryItems = [];
    if (this.inventories.length > 0) {
      this.inventories.map(inventory => {
        if (inventory.id === this.selectedInventoryId) {
          // this.filteredInventoryItems = inventory.items;
          this.selectedInventory = inventory;

          // Apply custom sorting to items
          // TODO Should be configured using sort order in backend
          this.getFilteredInventoryItems(this.selectedType);
        }
      });
    }
  }

  /**
   * Update inventory item
   */
  public onInventoryItemChange(inventoryItem) {
    this.updateInventoryItem(this.selectedType, inventoryItem);
    this.calculateTotals();
  }

  /**
   * Delete inventory item
   */
  public onInventoryItemDelete(inventoryItem) {
    this.removeInventoryItem(inventoryItem);
  }

  /**
   * Handle static item amount changes
   */
  public onAmountChange() {
    if (!this.selectedInventory) {
      return;
    }

    this.projectService.updateInventory(this.selectedInventory);
    this.packingTotal = this.projectService.calculatePackingTotal();
    this.assemblyTotal = this.projectService.calculateAssemblyTotal();
  }

  /**
   * Remove inventory item
   * @param inventoryItem
   */
  public removeInventoryItem(inventoryItem) {
    this.confirmationService.confirm({
      message: 'Wilt u dit item verwijderen?',
      header: 'Bevestiging',
      icon: 'fa fa-question-circle',
      accept: async () => {
        await this.projectService.deleteInventoryItem(inventoryItem.id);
        const index = this.filteredInventoryItems.indexOf(inventoryItem);
        this.filteredInventoryItems.splice(index, 1);
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.subscriptionInventoryDeleted) {
      this.subscriptionInventoryDeleted.unsubscribe();
    }

    if (this.subscriptionInventoryAdded) {
      this.subscriptionInventoryAdded.unsubscribe();
    }

    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }

    if (this.subscriptionInventoryItemAdded) {
      this.subscriptionInventoryItemAdded.unsubscribe();
    }
  }

  /**
   * Open add modal on click
   *
   * @param event: any
   * @return void
   */
  public openAddModal(event: any): void {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/inventory(popup:admin/project/inventory/add-item)');
  }

  /**
   * Sort the inventory items on alphabetical order, filtered on inventory type
   *
   * @param type: string
   * @return void
   */
  private getFilteredInventoryItems(type: string = 'move'): void {
    this.filteredInventoryItems = this.selectedInventory.items.slice()
        .filter((item) => item.inventory_type === type)
        .sort((a: any, b: any) => {
          if (a.name === b.name) {
            return 0;
          }
          // Verhuisdozen or Boekendozen should always be last
          if (a.name === 'Verhuisdozen' || a.name === 'Boekendozen') {
            return 1;
          }
          if (b.name === 'Verhuisdozen' || b.name === 'Boekendozen') {
            return -1;
          }
          return a.name > b.name ? 1 : -1;
        });
  }

  /**
   * Create inventory item
   *
   * @param type: string
   * @param inventoryItem: InventoryItem
   * @return Promise<void>
   */
  private async createInventoryItem(type: string, inventoryItem: InventoryItem): Promise<void> {
    // Add inventory item to current selected inventory and also update in store
    inventoryItem.inventory_id = this.selectedInventoryId;
    inventoryItem.inventory_type = type;
    this.selectedInventory.items.push(inventoryItem);
    await this.projectService.createOrUpdateInventoryItem(inventoryItem);
    this.getSelectedInventory();
  }

  /**
   * Update inventory item
   *
   * @param type: string
   * @param inventoryItem: InventoryItem
   * @return Promise<void>
   */
  private async updateInventoryItem(type: string, inventoryItem: InventoryItem): Promise<void> {
    inventoryItem.inventory_id = this.selectedInventoryId;
    inventoryItem.inventory_type = type;
    await this.projectService.createOrUpdateInventoryItem(inventoryItem);
  }

  /**
   * Recalculate totals
   */
  private calculateTotals(): void {
    this.volumeTotal = this.projectService.calculateVolume('move') + this.projectService.calculateVolume('storage');
    this.assemblyTotal = this.projectService.calculateAssemblyTotal();
    this.meterboxTotal = this.projectService.calculateMeterboxTotal();
  }
}
