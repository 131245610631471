import { DomainModel } from '@domain/domain.model';

import * as uuid from 'uuid/v4';

export class Contact extends DomainModel {
  // Configuration
  public entity = 'contact';
  public table = 'contacts';
  public schema = 'id, remote_id, client_id';
  public sync = true;
  public id: string;

  // Fields
  public client_id?: string;
  public name?: string;
  public email?: string;
  public phone?: string;
  public mobile?: string;
  public remarks?: string;

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public getData(): any {
    return {
      id: this.id,
      client_id: this.client_id,
      name: this.name,
      email: this.email,
      phone: this.phone,
      mobile: this.mobile,
      remarks: this.remarks
    };
  }
}
