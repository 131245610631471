import { Subject } from 'rxjs/Subject';
import Dexie from 'dexie';
import { DomainModel } from '@domain/domain.model';
import { Client } from '@domain/models/client.model';
import { Location } from '@domain/models/location.model';
import { RelationGroup } from '@domain/models/relation-group.model';
import { User } from '@domain/models/user.model';
import { Project } from '@domain/models/project.model';
import { Address } from '@domain/models/address.model';
import { AddressType } from '@domain/models/address-type.model';
import { Contact } from '@domain/models/contact.model';
import { Specialty } from '@domain/models/specialty.model';
import { Activity } from '@domain/models/activity.model';
import { ProjectActivity } from '@domain/models/project-activity.model';
import { ProjectSpecialty } from '@domain/models/project-specialty.model';
import { DefaultInventory } from '@domain/models/default-inventory.model';
import { DefaultInventoryItem } from '@domain/models/default-inventory-item.model';
import { Inventory } from '@domain/models/inventory.model';
import { InventoryItem } from '@domain/models/inventory-item.model';
import { DefaultItem } from '@domain/models/default-item.model';
import { Task } from '@domain/models/task.model';
import { Material } from '@domain/models/material.model';
import { Quotation } from '@domain/models/quotation.model';
import { QuotationTask } from '@domain/models/quotation-task.model';
import { QuotationMaterial } from '@domain/models/quotation-material.model';
import { BulkPrice } from '@domain/models/bulk-price.model';
import { WorkAssignment } from '@domain/models/work-assignment.model';
import { WorkAssignmentItem } from '@domain/models/work-assignment-item.model';
import { ProjectMaterial } from '@domain/models/project-material.model';

export class Store extends Dexie {
    private static _store: Store = null;
    private static _isReady: boolean;
    public static onReady: Subject<boolean> = new Subject<boolean>();

    private currentDbVersion = 222;
    public entities: Dexie.Table<DomainModel, number>[];

    // Define all models to be used in the Dexie database here
    public models: Array<any> = [
        Client,
        Location,
        RelationGroup,
        User,
        Project,
        AddressType,
        Address,
        Contact,
        Specialty,
        Activity,
        ProjectActivity,
        ProjectSpecialty,
        DefaultInventory,
        DefaultInventoryItem,
        Inventory,
        InventoryItem,
        DefaultItem,
        Task,
        Material,
        Quotation,
        QuotationTask,
        QuotationMaterial,
        BulkPrice,
        WorkAssignment,
        WorkAssignmentItem,
        ProjectMaterial
    ];

    /**
     * Create indexedDB tables
     */
    constructor() {
        super('holwerda_db', { autoOpen: true });
    }

    public async setup(): Promise<boolean> {
        if (Store._isReady) {
            return true;
        }

        if (await Dexie.exists('holwerda_db')) {
            await this.open();
            if (this.verno < this.currentDbVersion) {
                // Delete current outdated database if applicable
                await this.delete();
                await this.version(1).stores({});
            } else {
                await this.close();
            }
        }

        // Generate schema object
        const schema: any = {};

        // Add each model
        this.models.forEach(model => {
            const m = model.getInstance();
            schema[m.table] = m.schema;
        });

        // Create schema
        await this.version(this.currentDbVersion).stores(schema);
        await this.open();

        DomainModel.store = this;

        // Map each table to class model
        this.models.forEach(model => {
            const m = model.getInstance();
            this[m.table].mapToClass(model);
        });

        Store._isReady = true;
        Store.onReady.next(true);

        return true;
    }

    /**
     * Resets the database by removing and re-creating it
     */
    public async reset() {
        await this.delete();
        await this.setup();
    }

    /**
     * Return singleton instance
     */
    public static getStore(): Store {
        if (Store._store === null) {
            Store._store = new Store();
        }
        return Store._store;
    }
}
