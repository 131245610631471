import { Component, QueryList, ContentChildren, AfterContentInit } from '../../../../../node_modules/@angular/core';

import { SwitchOptionComponent } from './switch-option.component';

@Component({
  selector: 'em-switch',
  templateUrl: 'switch.component.html'
})
export class SwitchComponent implements AfterContentInit {
  @ContentChildren(SwitchOptionComponent) switchOptions: QueryList<SwitchOptionComponent>;

  public constructor() {}

  ngAfterContentInit() {
    this.parseSwitchOptions();
    this.switchOptions.changes.subscribe((r) => { this.parseSwitchOptions(); });
  }

  parseSwitchOptions() {
    setTimeout(() => {
      // Bind click events
      this.switchOptions.forEach(switchOption => {
        switchOption.click.subscribe((option) => {
            this.switchOptions.forEach(swo => swo.selected = false);
            option.selected = true;
        });
      });
     }, 0);
  }

  public getSelectedValue(): any {
    const selectedOption = this.switchOptions.find(option => {
      return option.selected;
    });
    return selectedOption ? selectedOption.value : null;
  }
}
