import { Injectable } from '@angular/core';
import { AuthService } from '@blueprint/auth/auth.service';

import * as jwtDecode from 'jwt-decode';
import { AbstractControl, FormControl } from '@angular/forms';

@Injectable()
export class UserService {

  constructor(private auth: AuthService) {}

  /**
   * Checks if the current user has the given role
   * @param roleCode Role code
   */
  public hasRole(roleCode: string): boolean {
    const jwt = this.getTokenAsObject();
    return jwt.role === roleCode;
  }

   /**
   * Checks if the current user had administrator rights
   * @param roleCode Role code
   */
  public isAdministrator(): boolean {
    const jwt = this.getTokenAsObject();

    if (jwt.role === 'superuser' || jwt.role === 'administrator') {
      return true;
    }

    return false;
  }

  /**
   * Decodes and returns the current JWT token string as an object
   */
  private getTokenAsObject(): any {
    // Get current JWT token
    const jwtToken = this.auth.getToken();

    // Decode JWT token
    return jwtDecode(jwtToken);
  }
}
