import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/toPromise';
import * as toastr from 'toastr';
import { ApiService } from '@blueprint/services/api.service';
import { AuthService } from '@blueprint/auth/auth.service';
import { Store } from '@domain/store';
import { Project } from '@domain/models/project.model';
import { Client } from '@domain/models/client.model';
import { Address } from '@domain/models/address.model';
import { Contact } from '@domain/models/contact.model';
import { ProjectActivity } from '@domain/models/project-activity.model';
import { ProjectSpecialty } from '@domain/models/project-specialty.model';
import { Inventory } from '@domain/models/inventory.model';
import { Quotation } from '@domain/models/quotation.model';
import { DataService } from '@shared/services/data.service';
import { WorkAssignment } from '@domain/models/work-assignment.model';

@Injectable()
export class SynchronisationService {
  public SynchronisingCompleted = new Subject<any>();
  public shouldSync: boolean;
  private store = Store.getStore();
  private state = { added: false, finished: false };
  private synchronisationFailed: boolean;

  constructor(private api: ApiService, private auth: AuthService, private dataService: DataService) {
    this.synchronisationFailed = false;

    // Register to internet connection online event
    window.addEventListener(
      'online',
      e => {
        if (this.shouldSync) {
          this.synchronise();
          this.shouldSync = false;
        }
      },
      false
    );
  }

  public async synchronise(): Promise<void> {
    // Do not synchronize when not authenticated
    if (!this.auth.isAuthenticated()) {
      return;
    }

    // Check internet status, if not online, then sync later
    if (!navigator.onLine) {
      this.shouldSync = true;

      return;
    }

    this.getListData().then(_ => {
      this.showSyncReadyToast();

      this.SynchronisingCompleted.next(this.state);
    });
  }

  public showSyncReadyToast(): void {
    toastr.success('Gereed voor gebruik', 'Applicatie');
  }

  public async syncToBackend(clearOnSuccess: boolean = false): Promise<boolean> {
    const projects = await Project.query.toArray();

    try {
      for (const project of projects) {
        // Check if id is set
        if (!project.id) {
          continue;
        }

        const data = await this.getSyncJson(project);

        const newData = JSON.parse(JSON.stringify(data));
        const originalData = project._original ? JSON.parse(JSON.stringify(project._original)) : {};

        // Compare new data and original
        const diff = this.getDiff(newData, originalData);

        // Apply changes to server
        let result = null;

        try {
          result = await this.api.post('/sync', [diff]).toPromise();

          if (result[0] !== 'ok') {
            // TODO Better error handling per project
            this.giveSynchronisationErrorToast();

            return false;
          } else {
            // Get latest data from server
            await this.loadSingleProjectData(project.id, true);
          }
        } catch (error) {
          this.giveSynchronisationErrorToast();
          this.synchronisationFailed = true;
        }
      }

      if (clearOnSuccess) {
        // Clear project data
        const projectTables = this.getProjectTables();
        Store.getStore().tables.forEach(table => {
          if (!projectTables.includes(table.name)) {
            return;
          }
          table.clear();
        });
      } else {
        // Update status
        const updateProjects = await Project.query.toArray();

        for (const project of updateProjects) {
          project.is_changed = false;
          project.is_new = false;
          await this.dataService.createOrUpdate('projects', project);
        }
      }

      return true;

    } catch (error) {
      this.synchronisationFailed = true;
      this.giveSynchronisationErrorToast();

      return false;
    }
  }

  /**
   * Retrieves a single projects from backend and updates the client store
   */
  public async loadSingleProjectData(projectId: string, forceLoad: boolean = false) {
    let result;
    try {
      result = await this.api.get('/project/' + projectId).toPromise();
    } catch (e) {
      // Ignore error
    }
    if (!result || !result.data || !result.data.projects || !result.data.projects[0]) {
      return;
    }

    // Check if project is already available locally
    const existingProject = await Project.query.get({
      id: result.data.projects[0].id
    });
    if (!forceLoad && existingProject) {
      return;
    }

    // Determine order of processing
    const tables = this.getProjectTables();

    for (const table of tables) {
      // Save data from backend in table
      if (result.data[table]) {
        await this.store.table(table).bulkPut(result.data[table]);

        delete result.data[table];
      }
    }

    // Store a copy of the project tree to track changes
    const updateProject = await Project.query.get({ id: projectId });
    if (updateProject) {
      const copy = await this.getSyncJson(updateProject);
      updateProject._original = JSON.parse(JSON.stringify(copy)); // Clone object
      await this.dataService.createOrUpdate('projects', updateProject);
    }
  }

  /**
   * Lists all tables containing project data
   */
  private getProjectTables(): string[] {
    return [
      'quotations',
      'quotation_materials',
      'quotation_tasks',
      'inventories',
      'inventory_items',
      'projects',
      'clients',
      'contacts',
      'addresses',
      'project_activities',
      'project_specialties',
      'project_users',
      'work_assignments',
      'work_assignment_items'
    ];
  }

  /**
  * Gives a generic synchronisation error toast to the user.
  */
  private giveSynchronisationErrorToast(): void {
    toastr.error('Synchroniseren van nieuwe data is mislukt. Probeer de pagina te vernieuwen.', 'Synchroniseren');
  }

  /**
   * Retrieves list and base data from backend and updates the client store
   */
  private async getListData(): Promise<void> {
    const result = await this.api.get('/sync/lists').toPromise();
    const models = Object.keys(result);
    for (const model of models) {
      // Clear contents of table and replace with backend data
      await this.store.table(model).clear();
      await this.store.table(model).bulkAdd(result[model]);
    }
  }

  /**
   * Retrieves the transformed project data used for synchronising data to backend
   */
  private async getSyncJson(project: Project): Promise<void> {
    // Gather project, client, address, contact, options and quotation data

    const item: any = {};

    // Project
    item.project = project.getData();

    // Address
    item.addresses = [];
    const addresses = await Address.query
      .where('project_id')
      .equals(project.id)
      .toArray();
    for (const address of addresses) {
      item.addresses.push(address.getData());
    }

    // Client
    item.client = null;
    if (project.client_id) {
      const client = await Client.query.get(project.client_id);
      if (client && client.id) {
        item.client = client.getData();
      }
    }

    // Contact
    item.contacts = [];
    if (project.client_id) {
      const contacts = await Contact.query
        .where('client_id')
        .equals(project.client_id)
        .toArray();
      for (const contact of contacts) {
        item.contacts.push(contact.getData());
      }
    }

    // Inventory
    item.inventories = [];
    item.inventory_items = [];
    const inventories = await Inventory.query
      .where('project_id')
      .equals(project.id)
      .toArray();
    for (const inventory of inventories) {
      item.inventories.push(inventory.getData());

      await inventory.init();

      // Inventory items
      for (const inventoryItem of inventory.items) {
        item.inventory_items.push(inventoryItem.getData());
      }
    }

    // Project activities
    item.project_activities = [];
    const projectActivities = await ProjectActivity.query
      .where('project_id')
      .equals(project.id)
      .toArray();
    for (const projectActivity of projectActivities) {
      item.project_activities.push(projectActivity.getData());
    }

    // Project specialties
    item.project_specialties = [];
    const projectSpecialties = await ProjectSpecialty.query
      .where('project_id')
      .equals(project.id)
      .toArray();
    for (const projectSpecialty of projectSpecialties) {
      item.project_specialties.push(projectSpecialty.getData());
    }

    // Quotation
    item.quotations = [];
    item.quotation_materials = [];
    item.quotation_tasks = [];
    const quotations = await Quotation.query
      .where('project_id')
      .equals(project.id)
      .toArray();
    for (const quotation of quotations) {
      item.quotations.push(quotation.getData());

      await quotation.init();

      // Quotation materials
      for (const quotationMaterial of quotation.materials) {
        item.quotation_materials.push(quotationMaterial.getData());
      }

      // Quotation tasks
      for (const quotationTask of quotation.tasks) {
        item.quotation_tasks.push(quotationTask.getData());
      }
    }

    // Work assignment
    item.work_assignments = [];
    item.work_assignment_items = [];
    const workAssignments = await WorkAssignment.query
      .where('project_id')
      .equals(project.id)
      .toArray();
    for (const workAssignment of workAssignments) {
      item.work_assignments.push(workAssignment.getData());

      await workAssignment.init();

      // Work assignment items
      for (const workAssignmentItem of workAssignment.items) {
        item.work_assignment_items.push(workAssignmentItem.getData());
      }
    }

    // Add project with associations to result
    return item;
  }

  private getDiff(newData: any, oldData: any): any {
    let result: any;
    if (Array.isArray(newData)) {
      result = [];
      for (const item of newData) {
        // Find item with same id in old data and compare
        const oldItem = oldData ? oldData.filter(o => o.id === item.id)[0] : undefined;
        if (!oldItem) {
          // Mark as new
          item._new = true;
          result.push(item);
        } else {
          // Item exists, add differences only
          const itemDiff = this.getDiff(item, oldItem);
          if (itemDiff) {
            // Always add id field
            itemDiff.id = item.id;
            result.push(itemDiff);
          }
        }
      }

      // Check if item is deleted
      if (oldData) {
        for (const oldDataItem of oldData) {
          if (newData.filter(o => o.id === oldDataItem.id).length === 0) {
            result.push({ _deleted: oldDataItem.id });
          }
        }
      }

      return result.length === 0 ? undefined : result;
    }

    result = {};
    for (const key of Object.keys(newData)) {
      const newEntity = newData[key];
      const oldEntity = oldData[key];

      // Always add if old item not exists
      if (newEntity && !oldEntity) {
        result[key] = newEntity;
        // Mark each array entry as _new if array
        if (Array.isArray(result[key])) {
          for (const item of result[key]) {
            if (typeof item === 'object') {
              // Mark as new
              item._new = true;
            }
          }
        } else if (typeof result[key] === 'object') {
          // Mark as new
          result[key]._new = true;
        }
        continue;
      }

      if (Array.isArray(newEntity)) {
        const itemDiff = this.getDiff(newEntity, oldEntity);
        if (itemDiff) {
          result[key] = itemDiff;
        }
        continue;
      }

      if (newEntity && typeof newEntity === 'object') {
        const itemDiff = this.getDiff(newEntity, oldEntity);
        if (itemDiff) {
          result[key] = itemDiff;
        }
        continue;
      }

      if (newEntity !== oldEntity) {
        result[key] = newEntity;
      }
    }

    // Add id field is result is available
    if (Object.keys(result).length === 0) {
      return undefined;
    }

    result.id = newData.id;
    return result;
  }
}
