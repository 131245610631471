import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '@blueprint/services/api.service';
import * as toastr from 'toastr';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Contact } from '@domain/models/contact.model';
import { FormBuilder, Validators } from '@angular/forms';
import { customEmailValidator } from '@shared/services/custom-validators.service';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-inventory-contacts-detail',
  templateUrl: 'contact-detail.component.html'
})
export class InventoryContactsDetailComponent implements OnInit, OnDestroy {
  public form;
  public showErrors = false;
  public errors: any = {};

  private contact = new Contact({});
  private project = new Project({});
  private routeContactId;

  public mode = {
    isAdd: true,
    alertText: 'Contact succesvol toegevoegd',
  };

  private subscriptionContactsChanged: Subscription;

  public constructor(
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private projectService: ProjectService) { }

  public ngOnInit(): any {
    this.project = this.projectService.getProject();
    this.initForm();

    this.route.params.subscribe(params => {
      this.routeContactId = params['id'];

      if (this.routeContactId) {
        this.mode.isAdd = false;
        this.projectService.getContact(this.routeContactId);
        this.subscriptionContactsChanged = this.projectService.contactsChanged.subscribe(contact => {
          this.contact = contact;
          this.initForm();
        });
      }
    });
  }

  public ngOnDestroy() {
    if (this.subscriptionContactsChanged) {
      this.subscriptionContactsChanged.unsubscribe();
    }
  }

  public initForm() {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control(this.contact.id),
      client_id: this.formBuilder.control(this.project.client.id || null),
      name: this.formBuilder.control(this.contact.name || '', Validators.required),
      email: this.formBuilder.control(this.contact.email || '', [customEmailValidator]),
      phone: this.formBuilder.control(this.contact.phone || '', Validators.required),
      mobile: this.formBuilder.control(this.contact.mobile || ''),
      remarks: this.formBuilder.control(this.contact.remarks),
    });
  }

  public onSubmit(form) {
    if (this.form.valid) {
      this.contact = this.form.value;
      this.projectService.saveContact(this.contact);
      this.onCloseClick();
    } else {
      this.showErrors = true;
    }
  }

  onCloseClick() {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/contact');
  }
}
