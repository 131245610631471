import { Component, OnInit, OnDestroy } from '@angular/core';
import { SelectItem } from 'primeng/primeng';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProjectService } from '@shared/services/project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Client } from '@domain/models/client.model';
import { Project } from '@domain/models/project.model';
import { DataService, QueryOptions } from '@shared/services/data.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-inventory-client',
  templateUrl: 'client.component.html',
  styleUrls: ['./client.component.scss']
})

/**
 * InventoryClientComponent
 */
export class InventoryClientComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public errors: any = {};
  public showErrors = false;
  public client = new Client({});
  public project = new Project({});
  public result;
  public usersList: SelectItem[] = [];
  public executorList: SelectItem[] = [];
  public statusList: SelectItem[] = [];
  public typeRemovalList: SelectItem[] = [];
  public typeDayList: SelectItem[] = [];
  public typeFloorList: SelectItem[] = [];
  public relationGroups: SelectItem[] = [];
  public locations: SelectItem[] = [];
  public typeDayIsSet: boolean = false;
  public typeFloorIsSet: boolean = false;

  private defaultLocation = { id: null, name: '' };
  private subscriptionProjectLoaded: Subscription;
  private subscriptionClientChanged: Subscription;

  /**
   * Constructor
   *
   * @param {FormBuilder} formBuilder
   * @param {ProjectService} projectService
   * @param {DataService} dataService
   * @param {ActivatedRoute} route
   * @param {Router} router
   */
  public constructor(
    private formBuilder: FormBuilder,
    private projectService: ProjectService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  public ngOnInit(): any {
    this.loadLists();

    this.project = this.projectService.getProject();

    if (this.project.client) {
      this.client = this.project.client;
    }

    // In case client has changed (selected from popup)
    this.subscriptionClientChanged = this.projectService.clientChanged.subscribe(
      (client) => {
        this.project.client_id = client.id;
        this.project.client = client;
        this.client = client;

        this.updateForm();
      }
    );

    // In case project loaded (in case of refresh)
    this.subscriptionProjectLoaded = this.projectService.projectLoaded.subscribe(
      (project) => {
        this.project = project;

        if (this.project.client) {
          this.client = this.project.client;
        } else {
          this.client = new Client({});
        }

        this.updateForm();
      }
    );

    this.initForm();
    this.updateForm();
    this.setValidations();
  }

  public async loadLists() {
    let queryOptions = new QueryOptions({
      sortColumn: 'name',
      usePaging: false
    });
    let result = await this.dataService.get(
      'locations',
      queryOptions,
      '/location-group/list'
    );
    this.locations = [];
    result.forEach(item => {
      this.locations.push({ label: item.name, value: item.id });
    }, this);

    result = await this.dataService.get(
      'relation_groups',
      queryOptions,
      '/relation-group/list'
    );
    this.relationGroups = [];
    result.forEach(item => {
      this.relationGroups.push({ label: item.name, value: item.id });
    }, this);

    queryOptions = new QueryOptions({
      sortColumn: 'name_lcase',
      usePaging: false
    });
    result = await this.dataService.get('users', queryOptions, '/users/list');

    this.usersList = [];
    result.forEach(item => {
      this.usersList.push({ label: item.name, value: item.id });
    }, this);

    this.executorList = [];
    result.forEach(item => {
      if (item.role.code === 'executor') {
        this.executorList.push({ label: item.name, value: item.id });
      }
    }, this);

    // Get default location
    const criteria = { name: 'Uithoorn' };

    this.defaultLocation = await this.dataService
      .getWhere('locations', criteria, '/locations?name=Uithoorn')
      .then(location => location);

    this.statusList = Project.getStatusList();
    this.typeRemovalList = Project.getTypeRemovalList();
    this.typeDayList = Project.getTypeDayList();
    this.typeFloorList = Project.getTypeFloorList();
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      reference_nr: this.formBuilder.control(
        { value: this.project.reference_nr || '' },
        [Validators.required]
      ),
      accountmanager_id: this.formBuilder.control({
        value: this.project.accountmanager_id
      }),
      name: this.formBuilder.control({ value: this.client.name || '' }, [
        Validators.maxLength(255), Validators.required
      ]),
      location_id: this.formBuilder.control({
        value: this.client.location_id || ''
      }),
      status: this.formBuilder.control({ value: this.project.status || '' }),
      type_removal: this.formBuilder.control({ value: this.project.type_removal || '' }, [Validators.required]),
      type_day: this.formBuilder.control({ value: this.project.type_day || '' }),
      type_floor: this.formBuilder.control({ value: this.project.type_floor || '' }),
      description: this.formBuilder.control({
        value: this.client.description || ''
      }),
      remarks: this.formBuilder.control({ value: this.client.remarks || '' })
    });
  }

  public updateForm(): void {
    this.form.reset({
      reference_nr: this.project.reference_nr || '',
      accountmanager_id: this.project.accountmanager_id,
      executor_id: this.project.executor_id,
      name: this.client.name || '',
      location_id: this.client.location_id || (this.defaultLocation ? this.defaultLocation.id : null),
      relation_group_id: this.client.relation_group_id || '',
      status: this.project.status || '',
      type_removal: this.project.type_removal || '',
      type_day: this.project.type_day || '',
      type_floor: this.project.type_floor || '',
      description: this.client.description || '',
      remarks: this.client.remarks || ''
    });
  }

  public setValidations(): void {
    if (this.form.controls['type_removal'].value === 'big-pieces') {
      this.typeDayIsSet = false;
    } else {
      this.form.get('type_day').setValidators(null);
      this.form.patchValue({type_day: null});
      this.form.get('type_day').clearValidators();
      this.project.type_day = null;
    }

    if (this.form.controls['type_removal'].value === 'special') {
      this.typeFloorIsSet = false;
    } else {
      this.form.get('type_floor').setValidators(null);
      this.form.patchValue({type_floor: null});
      this.form.get('type_floor').clearValidators();
      this.project.type_floor = null;
    }

    this.form.updateValueAndValidity();
  }

  public resetCalculatedBasePrice(): void {
    if (this.project.quotations && this.project.quotations.length > 0) {
      this.project.quotations[0].base_price = null; // Reset base price on quotation tab
    }
  }

  public setType(type): void {
    this.projectService.project.type = type;
    switch (type) {
      case 'business':
        this.projectService.project.projectType = 'Zakelijk';
        break;
      case 'private':
        this.projectService.project.projectType = 'Particulier';
        break;
      default:
        this.projectService.project.projectType = '?';
        break;
    }
  }

  public async ngOnDestroy(): Promise<void> {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }

    if (this.subscriptionClientChanged) {
      this.subscriptionClientChanged.unsubscribe();
    }

    await this.saveClient();
  }

  public async saveClient(): Promise<void> {
    if (this.form.valid) {
      this.client.name = this.form.controls.name.value;
      this.client.location_id = this.form.controls.location_id.value;
      this.client.description = this.form.controls.description.value;
      this.client.remarks = this.form.controls.remarks.value;

      this.project.client = this.client;
      this.project.client_id = this.client.id;
      this.project.reference_nr = this.form.value.reference_nr;
      this.project.status = this.form.value.status;
      this.project.type_removal = this.form.value.type_removal;
      this.project.type_day = this.form.value.type_day;
      this.project.type_floor = this.form.value.type_floor;
      this.project.accountmanager_id  = this.form.value.accountmanager_id;
      this.project.executor_id = this.form.value.executor_id;

      await this.projectService.saveClientAndProject();

      if (this.project.quotations && this.project.quotations.length > 0) {
        await this.projectService.saveQuotation(this.project.quotations[0]);
      }
    }
  }
}
