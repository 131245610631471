import { Component } from '@angular/core';
import { environment  } from '@environments/environment';

import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public version: string;
  public environment: any;

  constructor(private router: Router) {
    this.version = environment.version;
    this.environment = environment;
  }

  public routeToHome(): void {
    this.router.navigate([`/admin/projects`]);
  }
}
