import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { InventoryClientComponent } from '@features/inventory/client/client.component';


@Injectable()
export class ClientGuard implements CanDeactivate<InventoryClientComponent> {
  private home = '/admin/projects';
  private popup = 'popup';

  canDeactivate(
    component: InventoryClientComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState?: RouterStateSnapshot,
    nextState?: RouterStateSnapshot) {

    if (component.form && nextState.url !== this.home && !nextState.url.includes(this.popup)) {
      if (!component.form.valid) {
        component.showErrors = true;
        return false;
      }
    }
    return true;
  }
}
