import { DomainModel } from '@domain/domain.model';
import { Client } from '@domain/models/client.model';
import { WorkAssignmentItem } from '@domain/models/work-assignment-item.model';
import { Address } from '@domain/models/address.model';

import * as uuid from 'uuid/v4';

export class WorkAssignment extends DomainModel {
  // Configuration
  public entity = 'work-assignment';
  public table = 'work_assignments';
  public schema = 'id, project_id';
  public sync = true;
  public id: string;

  // Fields
  public project_id?: string;
  public pickup_address_id?: string;
  public deliver_address_id?: string;
  public assignment_date: string;
  public arrival_time: string;
  public departure_time: string;
  public description_own: string;
  public description_client: string;
  public signature_client_image?: string;
  public signature_teamleader_image?: string;

  public items: WorkAssignmentItem[] = [];

  public pickupAddress: Address;
  public deliverAddress: Address;
  public assignmentDate: Date;

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public async init() {
    this.assignmentDate = this.parseDate(this.assignment_date);

    // Set relations
    const workAssignmentItems = await WorkAssignmentItem.query
      .where('work_assignment_id')
      .equals(this.id)
      .toArray();
    if (workAssignmentItems) {
      workAssignmentItems.reverse();
    }
    this.items = [];
    for (const workAssignmentItem of workAssignmentItems) {
      await workAssignmentItem.init();
      this.items.push(workAssignmentItem);
    }

    if (this.pickup_address_id) {
      this.pickupAddress = await Address.query.get(this.pickup_address_id);
      if (this.pickupAddress) {
        await this.pickupAddress.init();
      }
    }
    if (this.deliver_address_id) {
      this.deliverAddress = await Address.query.get(this.deliver_address_id);
      if (this.deliverAddress) {
        await this.deliverAddress.init();
      }
    }
  }

  public getData() {
    return {
      id: this.id,
      project_id: this.project_id,
      pickup_address_id: this.pickup_address_id,
      deliver_address_id: this.deliver_address_id,
      assignment_date: this.formatDate(this.assignmentDate),
      arrival_time: this.arrival_time,
      departure_time: this.departure_time,
      description_own: this.description_own,
      description_client: this.description_client,
      signature_client_image: this.signature_client_image,
      signature_teamleader_image: this.signature_teamleader_image
    };
  }

  public updateDate() {
    this.assignment_date = this.formatDate(this.assignmentDate);
  }

  /**
   * Set default values
   */
  public setDefaults() {
    this.assignmentDate = new Date();
    this.assignment_date = this.formatDate(this.assignmentDate);

    this.departure_time = '8:00';
    this.arrival_time = '17:30';
  }
}
