import { Component, OnInit, OnDestroy } from '@angular/core';
import { SelectItem } from 'primeng/primeng';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { ProjectSpecialty } from '@domain/models/project-specialty.model';
import { ProjectActivity } from '@domain/models/project-activity.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-inventory-options-detail',
  templateUrl: 'options-detail.component.html',
  styleUrls: ['./options-detail.component.scss']
})
export class InventoryOptionsDetailComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public project = new Project({});
  public activities: ProjectActivity[] = [];
  public specialties: ProjectSpecialty[] = [];
  public value: Date;
  public numberTrucks: SelectItem[];
  public numberMovers: SelectItem[];
  public disabledDays: number[] = [];
  public packingTotal: number;
  public assemblyTotal: number;
  public selectedNumberMovers: string;
  public selectedNumberTrucks: string;
  public activityBoundaries: {
    minMovers: number,
    maxMovers: number,
    minVehicles: number,
    maxVehicles: number
  }[];

  private subscriptionProjectLoaded: Subscription;

  constructor(public projectService: ProjectService,
              private formBuilder: FormBuilder) {
    this.numberMovers = [
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
      { label: '4', value: '4' },
      { label: '5', value: '5' },
      { label: '8', value: '8' },
      { label: '10', value: '10' },
      { label: '15', value: '15' },
      { label: '20', value: '20' },
      { label: '25', value: '25' },
      { label: '50', value: '50' }
    ];

    this.numberTrucks = this.numberMovers;
  }

  public ngOnInit(): void {
    this.project = this.projectService.getProject();
    this.packingTotal = this.projectService.calculatePackingTotal();
    this.assemblyTotal = this.projectService.calculateAssemblyTotal();
    this.disableDays();
    this.specialties = this.project.specialties;
    this.activities = this.project.activities.filter(projectActivity => projectActivity.activity.project_type === 'business' || projectActivity.activity.project_type === 'private');

    // Reload when project changes
    this.subscriptionProjectLoaded = this.projectService.projectLoaded.subscribe((project: Project) => {
      this.project = project;
      this.disableDays();
      this.specialties = this.project.specialties;
      this.activities = this.project.activities.filter(projectActivity => projectActivity.activity.project_type === 'business' || projectActivity.activity.project_type === 'private');

      this.initForm();
    });

    // this.resetBoundaries();
    this.initForm();
  }

  // public updateAmountOfMovers(i: number): void {
  //   const amountOfTrucks = this.activities[i].number_trucks;
  //   this.activityBoundaries[i].minMovers = amountOfTrucks;
  //
  //   if (this.projectService.calculateVolume() < 17) {
  //     this.activityBoundaries[i].maxMovers = 3 * amountOfTrucks;
  //   } else {
  //     this.activityBoundaries[i].maxMovers = 4 * amountOfTrucks;
  //   }
  // }
  //
  // public getAmountOfVehicles(): number {
  //   return Math.ceil(this.projectService.calculateVolume() / 40);
  // }
  //
  // public setAmountOfVehicles(i: number): void {
  //   this.activityBoundaries[i].maxVehicles = Math.ceil(this.projectService.calculateVolume() / 40);
  // }
  //
  // public setBoundaries(i: number): void {
  //   let min: number;
  //
  //   if (this.activities[i].applicable) {
  //     min = 1;
  //
  //     this.activityBoundaries[i].minMovers = min;
  //     this.activityBoundaries[i].minVehicles = min;
  //   } else {
  //     min = 0;
  //
  //     this.activityBoundaries[i].minMovers = min;
  //     this.activityBoundaries[i].minVehicles = min;
  //   }
  //
  //   this.setAmountOfVehicles(i);
  //   this.setActivity(i, min);
  // }
  //
  // private setActivity(i: number, value: number): void {
  //   this.activities[i].startDate = null;
  //   this.activities[i].number_movers = value;
  //   this.activities[i].number_trucks = value;
  //   this.activities[i].details = null;
  // }
  //
  // private resetBoundaries(): void {
  //   this.activityBoundaries = [];
  //
  //   for (let i = 0; i < this.activities.length; i++) {
  //     this.activityBoundaries.push({
  //       maxMovers: this.projectService.calculateVolume() < 17 ? 3 : 4,
  //       maxVehicles: this.getAmountOfVehicles(),
  //       minVehicles: this.activities[i].applicable ? 1 : 0,
  //       minMovers: this.activities[i].applicable ? 1 : 0
  //     });
  //   }
  // }

  public initForm(): void {
    this.form = this.formBuilder.group({
      own_description_activities: this.formBuilder.control(this.project.own_description_activities || ''),
      client_description_activities: this.formBuilder.control(this.project.client_description_activities || '')
    });
  }

  public async ngOnDestroy(): Promise<void> {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }

    if (this.projectService.project) {
      this.projectService.project.own_description_activities = this.form.value.own_description_activities;
      this.projectService.project.client_description_activities = this.form.value.client_description_activities;

      await this.projectService.saveActivities(this.activities);
      await this.projectService.saveSpecialties(this.specialties);
      await this.projectService.setProjectUpdated();
      await this.projectService.saveProject();
    }
  }

  public disableDays(): void {
    if(this.project && this.project.type_day) {
      this.disabledDays = this.project.type_day === 'working-days' ? [0, 6] : [1, 2, 3, 4, 5];
    }
  }
}
