import { Component, ViewEncapsulation, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[asteriks]',
  templateUrl: './asteriks.component.html',
  styleUrls: ['./asteriks.component.scss']
})
export class AsteriksComponent {
  @Input() asteriks = true;
}
